import useMyAnalysesStore from '@/stores/me/analyses'
import useMyNotificationsStore from '@/stores/me/notifications'
import useMyPerspectivesStore from '@/stores/me/perspectives'

import hasDatasetConcern from './concerns/has-dataset-concern'

import api from '@/api'
import safeColors from '@/helpers/safe-colors'
import searchFilters from '@/helpers/search-filters'
import { labelFormatter } from '@/helpers/charts'
import { date, dateTime } from '@/helpers/datetime'

import { defineStore } from 'pinia'
import debounce from 'just-debounce-it'
import { shallowRef } from 'vue'

export const defineContentSentimentAnalysisStore = settings => {
    return defineStore({
        id: settings.id,

        state: () => ({
            analysis: null,

            id: null,
            name: 'Untitled analysis',
            type: 'content-sentiment',

            series: [],
            styles: {},

            date: { type: 'past', date: { past: 1, unit: 'years' }, },
            granularity: 'day',

            isTakingTooLong: false,

            isLoading: false,
            loadingPromise: null,
            abortLoading: null,

            isDirty: false,
            isSaving: false,

            analysisSeries: [],
            seriesGeneratedAt: null,

            seriesOptions: {
                limit: 1,
                hasColors: false,
                hasLabels: true,
                defaultMeta: { type: 'content' }
            },

            styleOptions: [
                {
                    name: 'Style', id: 'analysis.style', type: 'options', icon: 'chart',
                    options: [
                        { name: 'Absolute Sentiment', id: 'absolute' },
                        { name: 'Sentiment Ratio', id: 'ratio' }
                    ]
                },
                { name: 'Y-axis', id: 'analysis.y-axis', type: 'toggle' },
                { name: 'X-axis', id: 'analysis.x-axis', type: 'toggle' },
                { name: 'Legend', id: 'analysis.legend', type: 'toggle' }
            ],

            styleDefaults: {
                'analysis.style': 'ratio',
                'analysis.y-axis': 'enabled',
                'analysis.x-axis': 'enabled',
                'analysis.legend': 'enabled'
            },

            exportable: { chart: true, data: false },
            printable: true,

            passive: settings.passive || false,

            analysisRef: null,
            taskId: null,

            sentimentTypes: [
                { id: 'content', name: 'Content Sentiment' },
                { id: 'replies', name: 'Replies Sentiment' }
            ]
        }),

        getters: {
            analysisOptions: store => ({
                boost: { useGPUTranslations: true },
                chart: {
                    resetZoomButton: { position: { x: 0, y: 0 } },
                    spacing: [6, 12, 6, 12],
                    zoomType: 'x'
                },
                credits: { enabled: false },
                exporting: {
                    chartOptions: {
                        chart: {
                            spacing: [40, 40, 40, 40]
                        }
                    },
                    buttons: {
                        contextButton: { enabled: false }
                    }
                },
                legend: {
                    enabled: store.effectiveStyles['analysis.legend'] == 'enabled',
                    itemStyle: {
                        color: 'rgb(44, 64, 76)', cursor: 'pointer', fontFamily: 'Roboto, sans-serif', fontSize: '12px', fontWeight: '500', textOverflow: 'ellipsis'
                    },
                    labelFormatter: function () { return `<span style="color:${this.color}">${this.name}</span>` },
                    margin: 20,
                    padding: 0
                },
                plotOptions: {
                    column: {
                        marker: { enabled: false },
                        fillOpacity: 1
                    },
                    series: {
                        animation: ! settings.passive || true,
                        connectNulls: true,
                        dataLabels: { enabled: false },
                        enableMouseTracking: true,
                        marker: {
                            enabled: store.effectiveStyles['analysis.markers'] == 'show',
                            enabledThreshold: store.effectiveStyles['analysis.markers'] == 'auto' ? 4 : 0,
                            fillColor: '#fff',
                            lineColor: null,
                            lineWidth: 2,
                            radius: 4,
                            symbol: 'circle'
                        },
                        stacking: 'normal',
                        states: {
                            hover: { lineWidthPlus: 0 }
                        },
                        turboThreshold: 2500
                    }
                },
                series: store.analysisSeries.map(series => ({
                    type: store.effectiveStyles['analysis.style'] === 'ratio' && ['Positive', 'Negative', 'Neutral'].includes(series.label) ? 'column' : 'line',
                    name: series.label,
                    color: series.color,
                    threshold: 0,
                    visible: ! ((series.label === 'Average' && store.effectiveStyles['analysis.style'] === 'absolute') || (series.label === 'Neutral' && store.effectiveStyles['analysis.style'] === 'ratio')),
                    dashStyle: 'solid',
                    data: (store.effectiveStyles['analysis.style'] === 'ratio' && series.label === 'Negative') ? series.values.map(v => ({ x: v.x, y: -v.y, url: v.url })) : series.values,
                    yAxis: series.label === 'Average' ? 'ratio' : 'sentiment',
                    lineWidth: 2,
                    opacity: store.effectiveStyles['analysis.style'] === 'ratio' && series.label !== 'Average' ? 0.75 : 1,
                    point: {
                        events: {
                            click: function () { window.open(this.options.url, '_blank') }
                        }
                    }
                })),
                title: {
                    text: ''
                },
                tooltip: {
                    backgroundColor: 'rgba(107, 114, 128, 0.8)',
                    borderColor: 'rgb(156, 163, 175)',
                    borderRadius: 7,
                    hideDelay: 100,
                    padding: 8,
                    headerFormat: '',
                    pointFormatter: function () { return store.granularity === 'hour' ? dateTime(this.x) : date(this.x) + `<br>${this.series.name}<br><strong>${this.y}</strong>` },
                    shadow: false,
                    style: { color: '#fff', textAlign: 'center' }
                },
                xAxis: {
                    title: 'Time',
                    type: 'datetime',
                    labels: {
                        y: 30,
                        align: 'center',
                        enabled: store.effectiveStyles['analysis.x-axis'] == 'enabled',
                        style: { color: '#b8c3c9' },
                        formatter: labelFormatter
                    }
                },
                yAxis: [{
                    gridLineColor: '#e0e5e8',
                    id: 'sentiment',
                    min: store.effectiveStyles['analysis.style'] === 'ratio'
                        ? - (Math.max(...store.analysisSeries.filter(series => ['Positive', 'Negative', 'Neutral'].includes(series.label)).flatMap(series => series.values.map(v => v.y))) * 1.1)
                        : undefined,
                    max: store.effectiveStyles['analysis.style'] === 'ratio'
                        ? Math.max(...store.analysisSeries.filter(series => ['Positive', 'Negative', 'Neutral'].includes(series.label)).flatMap(series => series.values.map(v => v.y))) * 1.1
                        : undefined,
                    labels: {
                        enabled: store.effectiveStyles['analysis.y-axis'] == 'enabled',
                        padding: 0,
                        style: { color: '#b8c3c9' },
                        x: 1,
                        y: 14,
                        zIndex: 1
                    },
                    startOnTick: false,
                    endOnTick: false,
                    tickPixelInterval: 50,
                    title: { text: '' }
                }, {
                    gridLineWidth: 0,
                    id: 'ratio',
                    min: -1,
                    max: 1,
                    labels: {
                        enabled: store.effectiveStyles['analysis.y-axis'] == 'enabled' && store.effectiveStyles['analysis.style'] === 'ratio',
                        padding: 0,
                        style: { color: '#b8c3c9' },
                        x: 1,
                        y: 14,
                        zIndex: 1
                    },
                    opposite: true,
                    startOnTick: false,
                    endOnTick: false,
                    tickPixelInterval: 50,
                    title: { text: '' }
                }],
                time: {
                    useUTC: false
                }
            }),

            effectiveStyles: store => ({
                ...store.styleDefaults,
                ...store.styles
            }),

            analysisConfiguration: store => ({
                series: store.series.map(series => ({
                    color: series.color,
                    datasetId: series.datasetId,
                    datasetType: series.datasetType,
                    datasetFilters: series.datasetFilters,
                    label: series.label,
                    meta: series.meta
                })),
                styles: store.effectiveStyles,
                date: store.date,
                granularity: store.granularity
            }),

            hasValues: store => store.analysisSeries.some(series => series.values && series.values.length),
            isRenderable: store => store.analysisSeries.some(series => series.values && series.values.length > 1),

            selectedSentimentType: store => {
                return store.sentimentTypes.find(m => m && m.id == (store.series[0]?.meta.type || 'content'))
            },

            lowestNegativeValue: store => {
                return store.analysisSeries.find(s => s.label === 'Negative')?.values.map(v => v.y).reduce((a, b) => Math.min(a, b)) * -1
            },

            highestPositiveValue: store => {
                return store.analysisSeries.find(s => s.label === 'Positive')?.values.map(v => v.y).reduce((a, b) => Math.max(a, b))
            }
        },

        actions: {
            async initialize(analysis) {
                this.$reset()

                this.analysis = analysis

                this.id = analysis.id
                this.name = analysis.name

                this.date = analysis.configuration.date || this.date
                this.granularity = analysis.configuration.granularity || this.granularity

                this.series = analysis.configuration.series || this.series
                this.styles = analysis.configuration.styles || this.styles

                this.loadSeries()
            },

            async loadSeries() {
                if (this.loadingPromise) this.abortLoading.abort()

                this.isLoading = true

                setTimeout(() => {
                    if (this.isLoading) {
                        this.isTakingTooLong = true
                    }
                }, 5000)

                return this.loadingPromise = new Promise((accept, reject) => {
                    api.route('me analyses series')
                        .json({
                            type: this.type,
                            configuration: this.analysisConfiguration,
                            id: this.analysis?.id
                        })
                        .signal(this.abortLoading = new AbortController())
                        .post()
                        .error(422, () => {
                            this.isLoading = false
                        })
                        .json(response => {
                            this.analysisSeries = response.data?.map(series => ({
                                ...series,
                                values: series.values.map(point => ({
                                    x: Date.parse(point.date),
                                    y: Math.round(parseFloat(point.value) * 10000) / 10000, // rounding to 2 decimals
                                    url: point.url
                                }))
                            })) || []
                            this.seriesGeneratedAt = response.preparedAt ? new Date(response.preparedAt) : null

                            if (response.taskId) {
                                useMyNotificationsStore().onTaskCompletion(response.taskId, task => {
                                    this.analysisSeries = task.payload.data.map(series => ({
                                        ...series,
                                        values: series.values.map(point => ({
                                            x: Date.parse(point.date),
                                            y: Math.round(parseFloat(point.value) * 10000) / 10000, // rounding to 2 decimals
                                            url: point.url
                                        }))
                                    }))

                                    this.isLoading = this.isTakingTooLong = false
                                    this.taskId = null

                                    accept(this.analysisSeries)
                                })

                                this.taskId = response.taskId
                            } else {
                                this.isLoading = this.isTakingTooLong = false

                                accept(this.analysisSeries)
                            }
                        })
                })
            },

            loadSeriesDebounced: debounce(function () {
                this.loadSeries()
            }, 500),

            addSeries(data) {
                this.series.push({
                    label: data.label,
                    color: data.color,
                    datasetType: data.datasetType,
                    datasetId: data.datasetId,
                    meta: data.meta || { type: 'content' },
                    values: data.values || []
                })

                this.isDirty = true
                this.loadSeries()
            },

            updateSeries(series, data) {
                Object.assign(series, {
                    label: data.label || series.label,
                    color: data.color || series.color,
                    datasetType: data.datasetType || series.datasetType,
                    datasetId: data.datasetId || series.datasetId,
                    meta: data.meta || series.meta,
                    values: data.values || series.values
                })

                this.isDirty = true
                this.loadSeries()
            },

            deleteSeries(series) {
                const index = this.series.indexOf(series)

                if (index >= 0) {
                    this.series.splice(index, 1)

                    this.isDirty = true
                    this.loadSeries()
                }
            },

            isValidSeries(data) {
                return data.label && data.datasetType && data.datasetId && data.meta?.type
            },

            async fromQuery(query) {
                if (query.date) this.date = searchFilters().unserializeFrom('uri', 'date', query.date)

                await useMyPerspectivesStore().initialize()

                let perspective = useMyPerspectivesStore().find(query['perspective'])

                if (perspective) {
                    this.addSeries({
                        label: perspective.name,
                        color: safeColors.data().safeColors[0],
                        datasetType: 'perspective',
                        datasetId: perspective.id
                    })
                }
            },

            setDate(date) {
                this.date = date
                this.isDirty = true

                this.loadSeries()
            },

            setGranularity(granularity) {
                this.granularity = granularity
                this.isDirty = true

                this.loadSeries()
            },

            setStyle(name, value) {
                this.styles[name] = value
                this.isDirty = true
            },

            setAnalysisRef(analysis) {
                this.analysisRef = shallowRef(analysis)
            },

            setSentimentType(type) {
                this.series[0].meta.type = type
                this.isDirty = true

                this.loadSeries()
            },

            setSentimentView(view) {
                this.series[0].meta.view = view
            },

            print() {
                this.analysisRef.print()
            },

            exportChart(options) {
                return this.analysisRef.export(options)
            },

            save(notify = false) {
                this.isSaving = true

                return api.route(this.id ? 'me analyses update' : 'me analyses store', { id: this.id })
                    .json({
                        _method: this.id ? 'put' : 'post',
                        type: this.type,
                        name: this.name,
                        configuration: this.analysisConfiguration,
                        dependencies: this.resolveDependencies(),
                        notify: notify
                    })
                    .post()
                    .json(response => {
                        this.id = response.data.id
                    })
                    .finally(() => {
                        return useMyAnalysesStore().reload().then(() => {
                            this.isSaving = this.isDirty = false
                        })
                    })
            },

            resolveDependencies() {
                return {
                    'lists': this.series.filter(s => s.datasetType == 'list').map(s => s.datasetId),
                    'perspectives': this.series.filter(s => s.datasetType == 'perspective').map(s => s.datasetId),
                    'topics': this.series.filter(s => s.datasetType == 'topic').map(s => s.datasetId)
                }
            },

            abort() {
                if (this.abortLoading) this.abortLoading.abort()
            },

            ...hasDatasetConcern.actions
        }
    })
}

const useAnalysisAnalysesContentSentimentStore = defineContentSentimentAnalysisStore({id: 'contentSentimentAnalysis'})

export default useAnalysisAnalysesContentSentimentStore
