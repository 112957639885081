<template>
    <div>
        <div>
            <div class="flex h-screen bg-gray-75">
                <app-navigation v-if="!fullscreenMode"></app-navigation>

                <div class="flex-1 h-full overflow-y-auto" id="app-content">
                    <app-warnings v-if="!fullscreenMode"></app-warnings>
                    <router-view></router-view>
                </div>

                <notifications-toaster v-if="!fullscreenMode"></notifications-toaster>
            </div>
        </div>

        <notifications-overlay notifications-url="/api/me/notifications"></notifications-overlay>
        <help-overlay></help-overlay>
        <quick-search-overlay></quick-search-overlay>
        <support-chat-overlay></support-chat-overlay>
        <workspaces-modal></workspaces-modal>

        <auth-session-lost-modal></auth-session-lost-modal>
        <channel-history-download-modal></channel-history-download-modal>
        <content-detail-modal></content-detail-modal>
        <content-video-download-modal></content-video-download-modal>
        <debug-modal></debug-modal>
        <delete-confirmation-modal></delete-confirmation-modal>
        <workspace-switch-confirmation-modal></workspace-switch-confirmation-modal>
        <export-options-modal></export-options-modal>
        <my-channels-resource-unavailable-modal></my-channels-resource-unavailable-modal>
        <tags-manage-modal></tags-manage-modal>
        <channels-list-merge-modal></channels-list-merge-modal>
        <workspace-info-modal></workspace-info-modal>

        <div v-if="!fullscreenMode" class="bg-gray-900 rounded-lg shadow-md border border-gray-600 flex px-1 py-0.5 absolute right-4 bottom-4 z-40">
            <a href="#" @click.prevent="showQuickSearch({ hasFilterableFamilies: true, hasFilterableWorkspaces: true })" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 text-gray-400 hover:text-gray-50" v-tooltip.top="'Quick-Search'">
                <ui-icon name="search"></ui-icon>
            </a>

            <a href="#" @click.prevent="toggleSupportChatOverlay" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 hover:text-gray-50 relative" :class="isShowingSupportChat ? 'text-gray-50' : 'text-gray-400'" v-tooltip.top="'Support Chat'">
                <ui-icon name="message-circle"></ui-icon>
                <span class="absolute -bottom-1 block px-1 h-3 flex items-center justify-center rounded bg-red-500 text-2xs text-white" aria-hidden="true" v-if="unreadSupportMessagesCount">{{unreadSupportMessagesCount > 99 ? '✻' : unreadSupportMessagesCount}}</span>
            </a>

            <a href="#" @click.prevent="toggleNotificationsOverlay" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl rounded-sm transition ease-in-out duration-150 hover:text-gray-50 relative" :class="isShowingNotifications ? 'text-gray-50' : 'text-gray-400'" v-tooltip.top="'Notifications'">
                <ui-icon name="navigation.notifications"></ui-icon>
                <span class="absolute -bottom-1 block px-1 h-3 flex items-center justify-center rounded bg-red-500 text-2xs text-white" aria-hidden="true" v-if="unreadNotificationsCount">{{ unreadNotificationsCount > 99 ? '✻' : unreadNotificationsCount }}</span>
            </a>
        </div>

        <div class="bg-blue-700 text-white rounded-lg shadow-md border flex px-4 py-0.5 absolute right-40 bottom-4 z-40" v-if="$my.isImpersonating">
            <div class="h-10 flex items-center">
                <p>You're impersonating <span class="font-medium">{{$my.user.email}}</span>. <a class="underline font-medium" href="#" @click.prevent="$my.stopImpersonating">Vent</a>.</p>
            </div>
        </div>
    </div>
</template>

<script>
import AppNavigation from './AppNavigation'
import AppWarnings from './AppWarnings'
import HelpOverlay from '@/components/ui/help-overlay'
import NotificationsOverlay from '@/components/me/notifications/overlay'
import NotificationsToaster from '@/components/me/notifications/toaster'
import QuickSearchOverlay from '@/components/quick-search/overlay'
import SupportChatOverlay from '@/components/support/chat/overlay'

import AuthSessionLostModal from '@/components/auth/modals/session-lost'
import ChannelHistoryDownloadModal from '@/components/channels/modals/history-download'
import ContentDetailModal from '@/components/content/modals/content-detail'
import ContentVideoDownloadModal from '@/components/content/modals/video-download'
import DebugModal from '@/components/ui/debug-modal'
import DeleteConfirmationModal from '@/components/modals/delete-confirmation'
import ExportOptionsModal from '@/components/modals/export-options'
import MyChannelsResourceUnavailableModal from '@/components/channels/modals/my-channels-resource-unavailable'
import TagsManageModal from '@/components/me/tags/manage-modal'
import ChannelsListMergeModal from '@/components/channels/modals/list-merge'
import WorkspacesModal from '@/components/me/workspaces/modal'
import WorkspaceInfoModal from '@/components/me/workspaces/modals/workspace-info'
import WorkspaceSwitchConfirmationModal from '@/components/modals/workspace-switch-confirmation'

import useBoardStore from '@/stores/dashboards/board'
import useMyQuickSearchStore from '@/stores/me/quick-search'
import useSupportChatStore from '@/stores/support/chat'
import useMyNotificationsStore from '@/stores/me/notifications'

import { trackScrolling } from '@/helpers/scroll'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        AppNavigation, AppWarnings, HelpOverlay, NotificationsOverlay, NotificationsToaster, QuickSearchOverlay,
        SupportChatOverlay,
        AuthSessionLostModal, ChannelHistoryDownloadModal, ChannelsListMergeModal, ContentDetailModal,
        ContentVideoDownloadModal, DebugModal, DeleteConfirmationModal, ExportOptionsModal,
        MyChannelsResourceUnavailableModal, TagsManageModal, WorkspacesModal, WorkspaceInfoModal,
        WorkspaceSwitchConfirmationModal
    },

    computed: {
        ...mapState(useBoardStore, [ 'fullscreenMode' ]),
        ...mapState(useSupportChatStore, { unreadSupportMessagesCount: 'unseenAgentMessagesCount', isShowingSupportChat: 'isShown' }),
        ...mapState(useMyNotificationsStore, { unreadNotificationsCount: 'unreadNotificationsCount', isShowingNotifications: 'isOverlayShown' })
    },

    methods: {
        ...mapActions(useMyQuickSearchStore, { showQuickSearch: 'show' }),
        ...mapActions(useSupportChatStore, { toggleSupportChatOverlay: 'toggle' }),
        ...mapActions(useMyNotificationsStore, { toggleNotificationsOverlay: 'toggle' })
    },

    mounted() {
        trackScrolling()
    }
}
</script>
