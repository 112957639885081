<template>
    <ui-contextual-menu z-index="50">
        <template v-if="channelList.shared">
            <ui-dropdown-link icon="subscription" @click="subscribeToList(channelList)" v-if="! findList(channelList.id)">
                Subscribe
            </ui-dropdown-link>
            <ui-dropdown-link icon="subscription" @click="unsubscribeFromList(channelList)" v-else>
                Unsubscribe
            </ui-dropdown-link>
        </template>

        <template v-else>
            <ui-dropdown-link icon="pin-off" @click="updateList(channelList, { pinned: 1 })" v-if="! channelList.pinned">
               Pin
            </ui-dropdown-link>
            <ui-dropdown-link icon="pin-off" @click="updateList(channelList, { pinned: 0 })" v-if="channelList.pinned">
               Un-pin
            </ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="settings" @click="editList(channelList)">
               Settings
            </ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="merge" @click="mergeList(channelList, cardsStore)">
               Merge
            </ui-dropdown-link>
            <ui-dropdown-link icon="copy" @click="duplicateList(channelList, cardsStore)">
               Duplicate
            </ui-dropdown-link>
            <ui-dropdown-link icon="trash" @click="deleteList(channelList)">
               Delete
            </ui-dropdown-link>
        </template>

        <template v-if="$can('manage-world')">
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="wrench" @click="$inspect(channelList)">
                Debug
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useChannelsModalsListEditStore from '@/stores/channels/modals/list-edit'
import useChannelsModalsListDuplicateStore from '@/stores/channels/modals/list-duplicate'
import useChannelsModalsListMergeStore from '@/stores/channels/modals/list-merge'

import { mapActions } from 'pinia'

export default {
    props: [ 'cardsStore', 'channelList' ],

    methods: {
        ...mapActions(useMyChannelListsStore, [ 'subscribeToList', 'unsubscribeFromList' ]),
        ...mapActions(useMyChannelListsStore, {
            'findList': 'find',
            'updateList': 'update'
        }),
        ...mapActions(useChannelsModalsListEditStore, { 'editList': 'open' }),
        ...mapActions(useChannelsModalsListDuplicateStore, { 'duplicateList': 'open' }),
        ...mapActions(useChannelsModalsListMergeStore, { 'mergeList': 'open' }),

        async deleteList() {
            await useMyChannelListsStore().delete(this.channelList)

            if (this.cardsStore) this.cardsStore.show('lists')
        }
    }
}
</script>
