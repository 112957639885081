<template>
    <router-view></router-view>

    <Teleport to="#root">
        <notifications-manage-modal></notifications-manage-modal>
        <perspective-edit-modal></perspective-edit-modal>
        <topic-edit-modal></topic-edit-modal>
        <perspective-duplicate-modal></perspective-duplicate-modal>
        <review-overlay></review-overlay>
        <search-history-modal :stream-store="$page.streamStore"></search-history-modal>
        <sentiment-edit-modal></sentiment-edit-modal>
        <smart-tag-modal></smart-tag-modal>
        <channels-list-duplicate-modal></channels-list-duplicate-modal>
        <channels-list-edit-modal></channels-list-edit-modal>
        <topic-duplicate-modal></topic-duplicate-modal>
        <note-modal></note-modal>
        <content-hash-modal></content-hash-modal>
    </Teleport>
</template>

<script>
import NotificationsManageModal from './modals/notifications-manage'
import PerspectiveEditModal from './modals/perspective-edit.vue'
import TopicEditModal from './modals/topic-edit'
import PerspectiveDuplicateModal from './modals/perspective-duplicate'
import ReviewOverlay from './modals/review-overlay'
import SearchHistoryModal from '@/components/modals/search-history'
import SentimentEditModal from '@/components/content/modals/sentiment-edit'
import SmartTagModal from '@/components/content/modals/smart-tag'
import ChannelsListDuplicateModal from '@/components/channels/modals/list-duplicate'
import ChannelsListEditModal from '@/components/channels/modals/list-edit'
import TopicDuplicateModal from './modals/topic-duplicate'
import NoteModal from '@/components/me/notes/modal'
import ContentHashModal from '@/components/content/modals/content-hash'

export default {
    components: {
        NoteModal, NotificationsManageModal, PerspectiveEditModal, ChannelsListDuplicateModal,
        ChannelsListEditModal, TopicEditModal, PerspectiveDuplicateModal, SearchHistoryModal, SentimentEditModal,
        SmartTagModal, TopicDuplicateModal, ReviewOverlay, ContentHashModal
    }
}
</script>
