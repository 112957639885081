<template>
    <div class="flex flex-wrap items-center max-w-full relative gap-2 mt-2">
        <filter-date :filters="store.filters"></filter-date>
        <filter-notification :filters="store.filters" ephemeral></filter-notification>
        <filter-channels :filters="store.filters"></filter-channels>
        <filter-types :filters="store.filters"></filter-types>
        <filter-tags :filters="store.filters"></filter-tags>
        <filter-performance :filters="store.filters"></filter-performance>
        <filter-features :filters="store.filters" :stream-store="store"></filter-features>
        <filter-deleted :filters="store.filters"></filter-deleted>
        <filter-semantic :filters="store.filters" :stream-store="store"></filter-semantic>
        <filter-language :filters="store.filters"></filter-language>

        <div class="flex shrink-0 items-center ml-auto" v-if="showClearButton">
            <a href="#" @click="store.clearFilters" class="text-sm">
                Reset filters
            </a>
        </div>
    </div>
</template>

<script>
import FilterChannels from './search-filters/filter-channels'
import FilterDate from './search-filters/filter-date'
import FilterDeleted from './search-filters/filter-deleted'
import FilterFeatures from './search-filters/filter-features'
import FilterLanguage from './search-filters/filter-language'
import FilterNotification from './search-filters/filter-notification'
import FilterPerformance from './search-filters/filter-performance'
import FilterSemantic from './search-filters/filter-semantic'
import FilterTags from './search-filters/filter-tags'
import FilterTypes from './search-filters/filter-types'

export default {
    components: {
        FilterChannels, FilterDate, FilterDeleted, FilterFeatures, FilterLanguage, FilterNotification, FilterPerformance,
        FilterSemantic, FilterTags, FilterTypes
    },

    computed: {
        showClearButton() {
            return this.store.filters.isNotEmpty(false)
        }
    }
}
</script>
