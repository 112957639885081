import useModalsExportOptionsStore from '@/stores/modals/export-options'

import api, { asyncResource } from '@/api'
import { useModal } from '@/helpers'
import { channels } from '@/helpers/api-three-tables'

import useMyNotificationsStore from '@/stores/me/notifications'

import debounce from 'just-debounce-it'
import { defineStore } from 'pinia'

export const useMyMonitoredChannelsStore = defineStore({
        id: 'my-monitored-channels',

        state: () => ({
            resource: asyncResource({
                method: 'post',
                request: (api, store, payload) => store.channelsQuery().query(payload).toRequest(),
                paginated: true
            }),

            paginated: true,

            searchQuery: '',
            sorting: 'name-asc',
            filters: {},

            isInitialized: false,

            loadingPromise: null,
            lastReloadTime: +new Date()
        }),

        getters: {
            items: store => store.resource.data ?? [],
            isLoading: store => store.resource.isFetchingFirst
        },

        actions: {
            async initialize() {
                await this.load()
            },

            async load(force = false) {
                if (this.isInitialized && ! force) return Promise.resolve()
                if (this.loadingPromise) return this.loadingPromise

                return this.loadingPromise = this.resource.fetchFirst(this)
                    .then(() => this.loadingPromise = false)
            },

            async reload() {
                this.lastReloadTime = +new Date()
                return this.load(true)
            },

            async loadMore(infiniteScroll) {
                let items = await this.resource.fetchNext(this)

                items.length ? infiniteScroll.loaded() : infiniteScroll.complete()
            },

            async all() {
                await this.load()
                return this.items
            },

            filterBy: debounce(function(filters) {
                this.filters = filters
                this.reload()
            }, 250),

            sortBy(sorting) {
                this.sorting = sorting
                this.reload()
            },

            search: debounce(function (query) {
                this.searchQuery = query
                this.reload()
            }, 250),

            export() {
                useModalsExportOptionsStore().open({ query: this.channelsQuery() })
            },

            channelsQuery() {
                return channels()
                .filters({
                    ...this.filters,
                    list: 'monitored',
                    name: { query: this.searchQuery }
                })
                .sorting(this.sorting)
        },

        async enableMonitoring(channel) {
            channel.monitoringRequested = true

            await api.route('me channels monitoring store', { id: channel.id })
                .post()
                .error(400, () => useModal().show('channels-my-channels-resource-unavailable'))
                .res(() => {
                    useMyNotificationsStore().pushToast({
                        title: 'Adding your channel...',
                        body: 'This might take a few minutes, feel free to continue using the app.',
                        expires: 10
                    })
                })
        },

        disableMonitoring(channel) {
            api.route('me channels monitoring delete', { id: channel.id })
                .delete()
        },

        isMonitored(channel) {
            return !! channel.lists?.find(l => l.type == 'monitored')
        },

        toggleMonitoringOption(channel, option) {
            return channel.monitoringOptions.includes(option)
                ? this.disableMonitoringOption(channel, option)
                : this.enableMonitoringOption(channel, option)
        },

        enableMonitoringOption(chanel, option) {
            return api.route('me channels options store', { family: chanel.family, id: chanel.id, option })
                .post()
                .error(400, () => useModal().show('channels-my-channels-options-resource-unavailable'))
                .res(() => chanel.monitoringOptions.push(option))
        },

        disableMonitoringOption(channel, option) {
            return api.route('me channels options delete', { family: channel.family, id: channel.id, option })
                .delete()
                .res(() => channel.monitoringOptions = channel.monitoringOptions.filter(o => o != option))
        }
    }
})

export default useMyMonitoredChannelsStore
