<template>
    <div class="bg-white border border-gray-200 shadow-sm rounded-lg h-72 p-4 min-w-0 flex flex-col" :class="isComparing ? 'h-80' : 'h-72'">
        <div class="flex justify-between pb-2">
            <h1 class="text-lg font-medium text-gray-700">Hashtags</h1>
            <ui-data-quality-icon quality="observed"></ui-data-quality-icon>
        </div>

        <div class="flex flex-1 min-h-0 gap-x-4">
            <div v-for="series in series" :key="series.id" class="flex-1 min-w-0 flex flex-col relative">
                <div v-if="isComparing" class="mb-3">
                    <span class="rounded px-1.5 py-1 font-medium" :style="{ 'background-color': `${series.color}20`, 'color': series.color }">{{series.name}}</span>
                </div>

                <div class="space-y-1 mt-2" v-if="series.analysisStore().analysisSeries?.length">
                    <div :key="hashtag.name" v-for="hashtag in series.analysisStore().analysisSeries.slice(0, 6)">
                        <div class="flex justify-between">
                            <a :href="hashtag.url" target="_blank" class="font-medium">#{{hashtag.name}}</a>
                            <span class="text-gray-700">{{hashtag.count}}</span>
                        </div>
                        <div class="py-1">
                            <div class="bg-gradient-to-r rounded-full h-1" :style="{ 'width': `${hashtag.count / topCount(series.analysisStore()) * 100}%`, '--tw-gradient-stops': `${series.color}50, ${series.color}` }"></div>
                        </div>
                    </div>
                </div>

                <div class="h-full flex flex-col items-center justify-center" v-else>
                    <div class="text-gray-600 text-sm">No hashtags have been found.</div>
                </div>

                <div class="absolute inset-0 backdrop-blur-sm rounded-2xl flex items-center justify-center" :class="{'bg-opacity-20': isInitialized, 'bg-white': ! isInitialized}" v-if="series.analysisStore().isLoading">
                    <ui-spinner></ui-spinner>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapLocalState } from '@/helpers/pinia'

export default {
    computed: {
        ...mapLocalState([ 'analysisStore', 'isComparing', 'isInitialized', 'series' ])
    },

    methods: {
        topCount(store) {
            return Math.max(...store.analysisSeries.map(s => s.count))
        }
    }
}
</script>
