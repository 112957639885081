import Grid from '@/components/ui/cards/layouts/grid'

import defineCards from '@/stores/reusable/cards'
import useMyAnalysesStore from '@/stores/me/analyses'

import { sortAlphabetically } from '@/helpers/sorting'

import { markRaw } from 'vue'

export const useAnalysisIndexPinnedCardsStore = defineCards({
    id: 'analysisIndexPinnedCards',

    source: useMyAnalysesStore,

    filter: items => items.filter(item => item.pinned),

    layoutOptions: [
        {
            id: 'grid',
            component: markRaw(Grid),
            settings: { showCharts: true, rows: 2 }
        }
    ],

    sortingOptions: [
        {
            id: 'default', name: '', icon: '', default: true,
            apply: items => sortAlphabetically(items)
        }
    ]
})

export default useAnalysisIndexPinnedCardsStore
