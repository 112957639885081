<template>
    <div class="relative">
        <div @click.prevent="toggle()" ref="toggle">
            <slot name="trigger"></slot>
        </div>

        <Teleport to="#app">
            <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-class="opacity-0 scale-95"
                  enter-to-class="opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-class="opacity-100 scale-100"
                  leave-to-class="opacity-0 scale-95"
                >
                <div v-if="isOpen" :class="dropdownClass" :style="dropdownStyle" ref="dropdown" v-click-away="softClose">
                    <slot name="content" :align="align" :is-open="isOpen" :open="open" :close="close" :toggle="toggle"></slot>
                </div>
            </transition>
        </Teleport>
    </div>
</template>

<script>
import { scroll } from '@/helpers/scroll'

import { $vfm } from 'vue-final-modal'

export default {
    name: 'ui-dropdown',

    props: {
        'align': { default: 'left' },
        'disabled': { type: Boolean, default: false },
        'keepOpen': { type: Boolean, default: false },
        'position': { default: 'bottom' },
        'unstyled': Boolean,
        'width': { default: 'w-48' },
        'zIndex': {}
    },

    data: () => ({
        isOpen: false,
        links: [],

        dropdownStyle: {},
        scroll
    }),

    computed: {
        dropdownClass() {
            if (this.unstyled) return 'absolute'

            return `absolute rounded-lg py-0.5 drop-shadow-lg bg-white bg-opacity-95 backdrop-blur ring-1 ring-black ring-opacity-5 text-left ${this.width}`
        }
    },

    methods: {
        softClose() {
            if (this.keepOpen) return

            this.close()
        },

        close() {
            if (! this.isOpen) return

            this.isOpen = false
            this.closeSubmenus()

            this.$emit('closed')
        },

        open() {
            if (this.disabled) return

            this.isOpen = true

            this.$emit('opened')
        },

        toggle() {
            this.isOpen ? this.close() : this.open()

            if (this.isOpen) this.refreshDropdownStyles()
        },

        closeSubmenus() {
            this.links.forEach(link => {
                link.showSubmenu = false
                link.closeSubmenus()
            })
        },

        refreshDropdownStyles() {
            let toggleRect = this.$refs.toggle.getBoundingClientRect()

            this.$nextTick(() => {
                let dropdownRect = this.$refs.dropdown.getBoundingClientRect()

                let dropdownTop, dropdownBottom, dropdownLeft, dropdownRight

                if (this.position == 'left' || this.position == 'right') {
                    if (this.position == 'right' && toggleRect.right + dropdownRect.width + 12 <= window.innerWidth) {
                        dropdownLeft = toggleRect.right + 12
                    } else if (toggleRect.left - dropdownRect.width - 12 >= 0) {
                        dropdownRight = window.innerWidth - (toggleRect.left - 12)
                    } else {
                        dropdownLeft = toggleRect.right + 12
                    }
                }

                if (this.position == 'top' || this.position == 'bottom') {
                    if (this.position == 'bottom' && toggleRect.bottom + dropdownRect.height + 8 <= window.innerHeight) {
                        dropdownTop = toggleRect.bottom + 8
                    } else if (toggleRect.top - dropdownRect.height - 8 >= 0) {
                        dropdownBottom = window.innerHeight - (toggleRect.top - 8)
                    } else {
                        dropdownTop = toggleRect.bottom + 8
                    }
                }

                if (this.align == 'top' || this.align == 'bottom') {
                    if (this.align == 'top' && toggleRect.top + dropdownRect.height <= window.innerHeight) {
                        dropdownTop = toggleRect.top
                    } else if (toggleRect.bottom - dropdownRect.height >= 0) {
                        dropdownBottom = window.innerHeight - toggleRect.bottom
                    } else {
                        dropdownTop = toggleRect.top
                    }
                }

                if (this.align == 'left' || this.align == 'right') {
                    if (this.align == 'left' && toggleRect.right + dropdownRect.width <= window.innerWidth) {
                        dropdownLeft = toggleRect.left
                    } else if (toggleRect.right - dropdownRect.width >= 0) {
                        dropdownRight = window.innerWidth - toggleRect.right
                    } else {
                        dropdownLeft = toggleRect.left
                    }
                }

                this.dropdownStyle.top = dropdownTop ? `${dropdownTop}px` : undefined
                this.dropdownStyle.bottom = dropdownBottom ? `${dropdownBottom}px` : undefined
                this.dropdownStyle.left = dropdownLeft ? `${dropdownLeft}px` : undefined
                this.dropdownStyle.right = dropdownRight ? `${dropdownRight}px` : undefined
                this.dropdownStyle['z-index'] = this.resolveZIndex()
            })
        },

        resolveZIndex() {
            return $vfm.openedModals.length ? 9001 : (this.zIndex ?? 30)
        }
    },

    watch: {
        'scroll.position'() {
            if (this.isOpen && this.$refs.toggle && this.$refs.dropdown) {
                this.refreshDropdownStyles()
            }
        }
    }
}
</script>
