import { defineStore } from 'pinia'
import defineConnectionsGraph from "@/stores/connections/connections-graph.js";


const useGraphFiltersStore = defineStore({
    id: 'graph-filters',

    state: () => ({
        graphStore: null,

        graph: null,
        renderer: null,

        shownChannels: [],
        shownFeatures: [],
        shownEdges: [],

        channelsInGraph: [],
        featuresInGraph: [],
        edgesInGraph: [],

        leafsHidden: false,
    }),

    actions: {
        initialize(graph, renderer) {
            this.graph = graph
            this.renderer = renderer

            this.graphStore = defineConnectionsGraph({ id: 'connectionsGraph' })()
        },

        filtersActive() {
            if (
                this.shownChannels.length !== this.channelsInGraph.length ||
                this.shownFeatures.length !== this.featuresInGraph.length ||
                this.shownEdges.length !== this.edgesInGraph.length ||
                this.leafsHidden
            ) {
                return true
            }

            return false
        },

        resetFilters() {
            this.shownChannels = [ ...this.channelsInGraph ]
            this.shownFeatures = [ ...this.featuresInGraph ]
            this.shownEdges = [ ...this.edgesInGraph ]
            this.leafsHidden = false

            this.graph.forEachNode((n, a) => {
                a.hidden = false
            })

            this.graph.forEachEdge((e, a) => {
                a.hidden = false
            })

            this.renderer.refresh()
        },

        getSettings() {
            return {
                shownChannels: this.shownChannels,
                shownFeatures: this.shownFeatures,
                shownEdges: this.shownEdges,
                leafsHidden: this.leafsHidden
            }
        },

        setSettings(settings) {
            this.shownChannels = settings.shownChannels || this.shownChannels
            this.shownFeatures = settings.shownFeatures || this.shownFeatures
            this.shownEdges = settings.shownEdges || this.shownEdges
            this.leafsHidden = settings.leafsHidden || this.leafsHidden
        },

        setEntitiesInGraph(inGraph, resetFilters = true) {
            this.channelsInGraph = this.channelsInGraph.concat(inGraph.channelsInGraph)
            this.featuresInGraph = this.featuresInGraph.concat(inGraph.featuresInGraph)

            this.channelsInGraph = [...new Set([...this.channelsInGraph ,...inGraph.channelsInGraph])]
            this.featuresInGraph = [...new Set([...this.featuresInGraph ,...inGraph.featuresInGraph])]
            this.edgesInGraph = [...new Set([...this.edgesInGraph ,...inGraph.edgesInGraph])]

            if (resetFilters) {
                this.resetFilters()
            }
        },

        setShownChannels(shownChannels) {
            this.leafsHidden = false
            this.shownChannels = shownChannels
            let hidden = [ ...this.graphStore.hiddenNodes ]

            this.graph.forEachNode((n, a) => {
                if (this.graphStore.inspectedNodeIds.includes(a.node_id)) return

                if (a.class === 'Channel') {
                    if (this.shownChannels.includes(a.object_type)) {
                        a.hidden = false
                        hidden = [ ...hidden.filter(n => a.node_id != n) ]
                    } else {
                        a.hidden = true
                        hidden.push(a.node_id)
                    }
                }
            })

            this.graphStore.hiddenNodes = [ ...hidden ]
            this.renderer.refresh()
        },

        setShownFeatures(shownFeatures) {
            this.leafsHidden = false
            this.shownFeatures = shownFeatures
            let hidden = [ ...this.graphStore.hiddenNodes ]

            this.graph.forEachNode((n, a) => {
                if (this.graphStore.inspectedNodeIds.includes(a.node_id)) return

                if (a.class === 'Feature') {
                    if (this.shownFeatures.includes(a.object_type) || this.shownFeatures.includes(a.object_type)) {
                        a.hidden = false
                        hidden = [ ...hidden.filter(n => a.node_id != n) ]
                    } else {
                        a.hidden = true
                        hidden.push(a.node_id)
                        //this.hideIsolatedNeighbors(a.node_id)/**/
                    }
                }
            })

            this.graphStore.hiddenNodes = [ ...hidden ]
            this.renderer.refresh()
        },

        setShownEdges(shownEdges) {
            this.shownEdges = shownEdges
            let hidden = [ ...this.graphStore.hiddenNodes ]

            this.graph.forEachEdge((e, a) => {
                if (this.shownEdges.includes(a.label)) {
                    a.hidden = false
                    hidden = [ ...hidden.filter(n => a.label != n) ]
                } else {
                    a.hidden = true
                    hidden.push(a.label)
                }
            })

            this.graphStore.hiddenNodes = [ ...hidden ]
            this.renderer.refresh()
        },

        hideLeafs() {
            this.leafsHidden = !this.leafsHidden

            this.graph.forEachNode((n, a) => {
                if (a.nodeDegree < 2) {
                    a.hidden = this.leafsHidden
                    // this.hiddenNodes.push(n)
                }
            })
            this.renderer.refresh();
        },
    }
})

export default useGraphFiltersStore
