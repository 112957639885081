<template>
    <ui-dropdown width="w-auto">
        <template v-slot:trigger>
            <div class="ml-3">
                <button aria-label="Options" id="options-menu" aria-haspopup="true" aria-expanded="true">
                    <ui-icon class="text-3xl" name="more-horizontal"></ui-icon>
                </button>
            </div>
        </template>

        <template v-slot:content>
            <ui-dropdown-link icon="info" @click="showNodeInfo">Info</ui-dropdown-link>

            <ui-dropdown-link icon="search" @click="graphStore.showInGraph(node.node_id)">Show in graph</ui-dropdown-link>
            <ui-dropdown-link icon="share" @click="graphStore.inspectNode([node.node_id])" v-if="graphStore.nodes.length < 5000">Load connections for this node</ui-dropdown-link>

            <ui-dropdown-link icon="eye-off" @click="graphStore.hideNode([node.node_id])" v-if="!graphStore.hiddenNodes.includes(node.node_id)">Hide node</ui-dropdown-link>
            <ui-dropdown-link icon="eye" @click="graphStore.showNode([node.node_id])" v-if="graphStore.hiddenNodes.includes(node.node_id)">Show node</ui-dropdown-link>

            <ui-dropdown-link icon="hash" @click="graphStore.freezeNode([node.node_id])" v-if="!graphStore.frozenNodes.includes(node.node_id)">Freeze node</ui-dropdown-link>
            <ui-dropdown-link icon="hash" @click="graphStore.freezeNode([node.node_id])" v-if="graphStore.frozenNodes.includes(node.node_id)">Unfreeze node</ui-dropdown-link>

            <ui-dropdown-link icon="trash" @click="graphStore.deleteNode([node.node_id])">Delete node</ui-dropdown-link>
        </template>
    </ui-dropdown>
</template>

<script>

import defineConnectionsGraph from "@/stores/connections/connections-graph.js";
import useConnectionsModalsNodeDetailsStore from "@/stores/connections/modals/node-details.js";


export default {
    data: () => ({
        graphStore: defineConnectionsGraph({ id: 'connectionsGraph' })(),
        node: {},
    }),

    methods: {
        showNodeInfo() {
            useConnectionsModalsNodeDetailsStore().open(this.node.class, this.node.node_id)
        }
    },

    beforeMount() {
        this.node = this.params.data
    },
}
</script>
