import useDeleteConfirmationModal from '@/stores/modals/delete-confirmation'
import useIndexCardsListsListStore from '@/stores/channels/index-cards-lists-list'
import useModalsExportOptionsStore from '@/stores/modals/export-options'

import { default as api, channels } from '@/api'

import { defineStore } from 'pinia'
import useChannelsModalsListDetachConfirmationStore from '@/stores/channels/modals/list-detach-confirmation'

export const useMyChannelListsStore = defineStore({
    id: 'channel-lists',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    actions: {
        async initialize() {
            await this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me lists').get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        },

        async all() {
            await this.load()
            return this.items
        },

        find(id) {
            return this.items.find(sl => sl.id == id)
        },

        async update(list, data) {
            await api.route('me lists update', list.id)
                .formData({ _method: 'put', ...data })
                .post()
                .res()

            return this.reload()
        },

        async delete(list, force = false) {
            await api.route('me lists delete', list.id)
                .formData({ _method: 'delete', force: force ? 1 : 0 })
                .post()
                .forbidden(async (res) => {
                    if (res.json.error == 'has-dependents') {
                        return useDeleteConfirmationModal().open('List', res.json.dependents)
                            .then(() => this.delete(list, true))
                    }
                })
                .res()

            return this.reload()
        },

        async safeDelete(list) {
            try {
                return await this.delete(list)
            } catch (e) {}
        },

        including(channel) {
            let lists = this.items.filter(list => ! list.curated)

            return channel.lists ? lists.filter(l => this.isIncludedIn(l, channel)) : []
        },

        notIncluding(channel) {
            let lists = this.items.filter(list => ! list.curated)

            return channel.lists ? lists.filter(l => ! this.isIncludedIn(l, channel)) : []
        },

        isIncludedIn(list, channel) {
            return channel.lists.find(l => l.id == list.id)
        },

        async addToList(list, channel) {
            return api.route(`me lists channels store`, { id: list.id, channelId: channel.id }).post().res(() => {
                // @todo: this doesn't have to be reloaded, just push the list into the channel.lists
                return api.route(`monitor channels details`, { id: channel.id }).get().json(res => {
                    channel.lists = res.data.lists
                })
            })
        },

        async removeFromList(list, channel, force = false) {
            if (! force && ! channel.lists.some(l => l.id === list.id)) {
                await useChannelsModalsListDetachConfirmationStore().open()
                    .then(async () => {
                        await this.removeFromList(list, channel, true)
                    })
            }

            return api.route(`me lists channels delete`, { id: list.id, channelId: channel.id }).delete().res(() => {
                useIndexCardsListsListStore().source().reload()

                // @todo: this doesn't have to be reloaded, just remove the list from the channel.lists
                return api.route(`monitor channels details`, { id: channel.id }).get().json(res => {
                    channel.lists = res.data.lists
                })
            })
        },

        subscribeToList(list) {
            if (! list.curated) return

            return api.route('me lists subscription store', { id: list.id }).post().res(() => {
                this.reload()
            })
        },

        unsubscribeFromList(list) {
            if (! list.curated) return

            return api.route('me lists subscription store', { id: list.id }).delete().res(() => {
                this.reload()
            })
        },

        isCurated(list) {
            return list.curated
        },

        isSubscribedByParent(list) {
            return this.items.find(l => l.parentList?.id === list.id)
        },

        isSubscribedByChild(list) {
            return !! list.parentList
        },

        export(listOrId) {
            let list = listOrId instanceof Object ? listOrId : this.find(listOrId)

            useModalsExportOptionsStore().open({ query: channels()
                .filters({ list: list.id })
                .sorting('name-asc')  })
        }
    }
})

export default useMyChannelListsStore
