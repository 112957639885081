<template>
    <div class="flex bg-white/80 backdrop-blur-sm border border-gray-200 rounded-md shadow-sm text-gray-700">
        <a href="#" class="flex items-center justify-center w-10 h-10 text-xl hover:text-gray-900" v-tooltip="'Zoom out'" @click.prevent="store.zoomOut">
            <ui-icon name="zoom-out"></ui-icon>
        </a>
        <a href="#" class="flex items-center justify-center w-10 h-10 text-xl hover:text-gray-900" v-tooltip="'Zoom in'" @click.prevent="store.zoomIn">
            <ui-icon name="zoom-in"></ui-icon>
        </a>
        <a href="#" class="flex items-center justify-center w-10 h-10 text-xl hover:text-gray-900" v-tooltip="'Fullscreen mode'" @click.prevent="toggleFullscreen">
            <ui-icon :name="isFullscreen ? 'minimize-2' : 'maximize-2'"></ui-icon>
        </a>
        <a href="#" class="flex items-center justify-center w-10 h-10 text-xl hover:text-gray-900" v-tooltip="'Save graph as image'" @click.prevent="store.exportAsImage">
            <ui-icon name="image"></ui-icon>
        </a>
    </div>
</template>

<script>

export default {
    props: {
        store: Object,
        pageContainer: Object,
    },

    data: () => ({
        isFullscreen: false,
    }),

    methods: {
        toggleFullscreen() {
            if (this.isFullscreen) {
                document.exitFullscreen().then(() => this.isFullscreen = false)
            } else {
                this.pageContainer.requestFullscreen().then(() => this.isFullscreen = true)
            }
        }
    }
}
</script>
