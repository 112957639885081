import { api, route } from '@/api'

import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMyTopicsStore from '@/stores/me/topics'

export default {
    state: () => ({
        appliedPerspective: null,

        isSavingPerspective: false,
        isSavingSubscriptions: false
    }),

    getters: {
        hasUnsavedFilters() {
            if (! this.appliedPerspective) return this.appliedFilters.length > 0

            return JSON.stringify(this.appliedPerspective.filters) != this.getContentQueryFilters(false)
                || this.appliedPerspective.meta.sorting?.option != this.sorting.id
                || this.appliedPerspective.meta.sorting?.direction != this.sorting.direction
                || this.appliedPerspective.meta.layout != this.layout.id
        }
    },

    actions: {
        applyPerspective(perspective, query = {}) {
            if (this.appliedPerspective?.id == perspective?.id) return false

            this.appliedPerspective = perspective

            if (! perspective || this.appliedFilters.length) return false

            let overrideFilters = Object.entries(query).map(([ key, value ]) => {
                let matches = key.match(/^override-filter\[(.*?)\]$/)

                if (! matches) return

                let filter = this.filters.find(f => f.id == matches[1])
                value = this.unserializeFilterFrom('uri', filter, value)

                return { filter, value }
            }).filter(v => v && v.value)

            let searchFilters = Object.entries(perspective.filters)
                .filter(([filter]) => ! overrideFilters.find(a => a.filter.id == filter))
                .map(([filter, value]) => {
                    filter = this.filters.find(f => f.id == filter)
                    value = this.unserializeFilterFrom('api', filter, value)

                    return { filter, value }
                }).filter(v => v && v.value)

            this.appliedFilters = [ ...overrideFilters, ...searchFilters ]

            this.appliedSearchTopic = useMyTopicsStore().find(this.appliedPerspective.topicId)
            this.searchQuery = this.appliedSearchTopic ? '' : this.appliedFilters.find(a => a.filter.search)?.value.query
            this.searchTypes = this.appliedFilters.find(a => a.filter.search)?.value.types || [ 'images', 'videos' ]

            if (perspective.meta?.sorting?.option) {
                this.setSorting(perspective.meta.sorting.option, perspective.meta.sorting.direction)
            }

            if (perspective.meta?.layout) {
                this.setLayout(perspective.meta.layout)
            }

            this.triggerQueryChange()

            return true
        },

        createPerspective() {
            this.appliedPerspective = { id: null, name: null, filters: [], subscriptions: { me: [], workspace: [] } }
        },

        async savePerspective() {
            if (! this.appliedPerspective.name || this.isSavingPerspective) return

            this.isSavingPerspective = true

            let endpoint = this.appliedPerspective.id
                ? route('me perspectives update', this.appliedPerspective.id)
                : route('me perspectives store')

            let resp = await api.url(endpoint)
                .formData({
                    _method: this.appliedPerspective.id ? 'put' : 'post',
                    name: this.appliedPerspective.name,
                    filters: this.getContentQueryFilters(false),
                    'sorting[option]': this.sorting.id,
                    'sorting[direction]': this.sorting.direction,
                    layout: this.layout.id,
                    topicId: this.appliedSearchTopic?.id,
                    virtual: this.appliedPerspective.virtual ? 1 : 0
                })
                .post()
                .json()

            await useMyPerspectivesStore().reload()

            this.appliedPerspective = resp.data

            this.isSavingPerspective = false

            this.triggerQueryChange()
        },

        async deletePerspective() {
            try {
                await useMyPerspectivesStore().delete(this.appliedPerspective)
            } catch (e) {
                return
            }

            this.appliedPerspective = null

            this.triggerQueryChange()
        },

        subscribeTo(type, configuration = {}, target = 'me') {
            if (! this.appliedPerspective.subscriptions[target].types.includes(type)) {
                this.appliedPerspective.subscriptions[target].types.push(type)
            }

            this.appliedPerspective.subscriptions[target].configuration[type] = configuration

            this.saveSubscriptions()
        },

        unsubscribeFrom(type, target = 'me') {
            if (type == 'in-app') {
                this.appliedPerspective.subscriptions[target].types = []
                this.appliedPerspective.subscriptions[target].configuration = {}
            } else {
                this.appliedPerspective.subscriptions[target].types = this.appliedPerspective.subscriptions[target].types.filter(t => t != type)
                this.appliedPerspective.subscriptions[target].configuration[type] = {}
            }

            this.saveSubscriptions()
        },

        toggleSubscription(type, target = 'me') {
            this.appliedPerspective.subscriptions[target].types.includes(type)
                ? this.unsubscribeFrom(type, target)
                : this.subscribeTo(type, {}, target)
        },

        muteSubscription(mutedUntil, target = 'me') {
            this.appliedPerspective.subscriptions[target].muted = mutedUntil

            this.saveSubscriptions()
        },

        unmuteSubscription(target = 'me') {
            this.appliedPerspective.subscriptions[target].muted = false

            this.saveSubscriptions()
        },

        async saveSubscriptions() {
            this.isSavingSubscriptions = true

            await api.route('me perspectives subscriptions update', this.appliedPerspective.id)
                .formData({
                    _method: 'put',
                    subscriptions: JSON.stringify(this.appliedPerspective.subscriptions)
                })
                .post()
                .res()

            this.isSavingSubscriptions = false
        }
    }
}