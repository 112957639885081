<template>
    <div class="p-4">
        <div class="block text-sm font-medium leading-6 text-gray-900"></div>

        <ui-dropdown>
            <template v-slot:trigger>
                <a class="border border-gray-300 hover:border-gray-400 text-gray-700 hover:text-gray-900 rounded-md font-medium flex justify-between items-center h-full px-3 py-2" href="#">
                    <span class="text-gray-500 mr-1 capitalize">{{ algorithmOptions[algorithm] }}</span>
                    <ui-icon name="select" class="ml-2 "></ui-icon>
                </a>
            </template>

            <template v-slot:content>
                <ui-dropdown-link v-for="(value, key) in algorithmOptions" :key="key" @click="selectMetrics(key)">{{ value }}</ui-dropdown-link>
            </template>
        </ui-dropdown>

        <div class="text-sm mt-3 text-center">
            <span v-if="algorithm == 'centrality'">Betweenness centrality measures the importance of a node in a graph by quantifying how often it appears on the shortest paths between other node pairs, indicating its role as a connector or bridge within the network.</span>
            <span v-if="algorithm == 'pagerank'">PageRank is a metric that evaluates the importance of a node in a graph based on the number and quality of links to it, with highly-linked nodes contributing more to the importance score of connected nodes.</span>
            <span v-if="algorithm == 'eigenvector'">Eigenvector centrality measures a node’s influence in a graph by assigning higher scores to nodes connected to other highly influential nodes, capturing both direct and indirect connections to reflect overall importance.</span>
        </div>

        <ui-dropdown-separator class="my-4"></ui-dropdown-separator>

        <div v-if="processing" class="flex items-center justify-center mt-2 mb-2">
            <ui-spinner type="clip"></ui-spinner>
            <div class="ml-2">Computing metrics...</div>
        </div>

        <div v-if="!processing && computed[algorithm]" class="flex items-center justify-center mt-2">
            <u-i-icon name="check-circle" class="mr-2 text-green-500 text-2xl"></u-i-icon>
            <span class="">Metrics computed</span>
        </div>

        <div class="mt-2">
            <ui-button class="w-full" color="blue" icon="play" @click="runMetrics" :disabled="processing || computed[algorithm]">Calculate</ui-button>
        </div>
    </div>
</template>


<script>

import { mapActions, mapState } from "pinia"
import UiButton from "@/components/ui/button.vue"
import useGraphMetricsStore from "@/stores/connections/graph-metrics.js"
import UIIcon from "@/components/ui/icon.vue";


export default {
    components: { UIIcon, UiButton },

    data: () => ({
        algorithmOptions: {
            centrality: 'Betweenness centrality',
            eigenvector: 'Eigenvector centrality',
            pagerank: 'Pagerank',
        }

    }),

    computed: {
        ...mapState(useGraphMetricsStore, [ 'algorithm', 'processing', 'computed' ]),
    },

    methods: {
        ...mapActions(useGraphMetricsStore, [ 'selectMetrics', 'runMetrics' ]),
    },
}
</script>
