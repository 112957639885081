<template>
    <div class="p-4 overflow-x-auto">
        <div class="text-xl">Nodes</div>
        <div class="block text-sm font-medium leading-6 text-gray-900">Size</div>
        <ui-dropdown align="left">
            <template v-slot:trigger>
                <a class="border border-gray-300 hover:border-gray-400 text-gray-700 hover:text-gray-900 rounded-md font-medium flex justify-between items-center h-full px-3 py-2" href="#">
                    <span class="text-gray-500 mr-1">{{ nodeSizeByOptions[nodeSizeBy] }}</span>
                    <ui-icon name="select" class="ml-2 "></ui-icon>
                </a>
            </template>

            <template v-slot:content>
                <ui-dropdown-link v-for="(value, key) in nodeSizeByOptions" :key="key" @click="setNodeSize(key)">{{ value }}</ui-dropdown-link>
            </template>
        </ui-dropdown>

        <input v-if="nodeSizeBy == 'fixed'" type="range" min="3" max="22" step="1" class="w-full h-1 my-4" v-model="sizeSlider">

        <div class="mt-2"  v-if="nodeSizeBy == 'attribute'">
            <div class="block text-sm font-medium leading-6 text-gray-900s">Size by attribute:</div>

            <ui-dropdown>
                <template v-slot:trigger>
                    <a class="border border-gray-300 hover:border-gray-400 text-gray-700 hover:text-gray-900 rounded-md font-medium flex justify-between items-center h-full px-3 py-2" href="#">
                        <span class="text-gray-500 mr-1">{{ nodeSizeByAttrOptions[nodeSizeByAttr] }}</span>
                        <ui-icon name="select" class="ml-2 "></ui-icon>
                    </a>
                </template>

                <template v-slot:content>
                    <ui-dropdown-link @click="setNodeSize('attribute', 'nodeDegree')">Node degree</ui-dropdown-link>
                    <ui-dropdown-link @click="setNodeSize('attribute', 'followers')">Followers</ui-dropdown-link>

                    <ui-dropdown-link v-if="computed && computed['pagerank']" @click="setNodeSize('attribute', 'pagerank')">Pagerank</ui-dropdown-link>
                    <ui-dropdown-link v-if="computed && computed['centrality']" @click="setNodeSize('attribute', 'centrality')">Betweenness centrality</ui-dropdown-link>
                    <ui-dropdown-link v-if="computed && computed['eigenvector']" @click="setNodeSize('attribute', 'eigenvector')">Eigenvector centrality</ui-dropdown-link>
                </template>
            </ui-dropdown>
        </div>

        <div class="block text-sm font-medium leading-6 text-gray-900s mt-2">Style</div>
        <ui-dropdown>
            <template v-slot:trigger>
                <a class="border border-gray-300 hover:border-gray-400 text-gray-700 hover:text-gray-900 rounded-md font-medium flex justify-between items-center h-full px-3 py-2" href="#">
                    <span class="text-gray-500 mr-1">{{ nodeStyleOptions[nodeStyle] }}</span>
                    <ui-icon name="select" class="ml-2 "></ui-icon>
                </a>
            </template>

            <template v-slot:content>
                <ui-dropdown-link v-for="(value, key) in nodeStyleOptions" :key="key" @click="setNodeStyle(key)">{{ value }}</ui-dropdown-link>
            </template>
        </ui-dropdown>

        <div class="mt-2" v-if="nodeStyle == 'color'">
            <div class="block text-sm font-medium leading-6 text-gray-900s">Color by attribute:</div>

            <ui-dropdown>
                <template v-slot:trigger>
                    <a class="border border-gray-300 hover:border-gray-400 text-gray-700 hover:text-gray-900 rounded-md font-medium flex justify-between items-center h-full px-3 py-2" href="#">
                        <span class="text-gray-500 mr-1">{{ nodeColorAttrOptions[nodeColorAttr] }}</span>
                        <ui-icon name="select" class="ml-2 "></ui-icon>
                    </a>
                </template>

                <template v-slot:content>
                    <ui-dropdown-link @click="setNodeStyle('color', 'nodeDegree')">Node degree</ui-dropdown-link>
                    <ui-dropdown-link @click="setNodeStyle('color', 'followers')">Followers</ui-dropdown-link>
                    <ui-dropdown-link @click="setNodeStyle('color', 'subclass')">Subclass</ui-dropdown-link>

                    <ui-dropdown-link v-if="computed && computed['pagerank']" @click="setNodeStyle('color', 'pagerank')">Pagerank</ui-dropdown-link>
                    <ui-dropdown-link v-if="computed && computed['centrality']" @click="setNodeStyle('color', 'centrality')">Betweenness centrality</ui-dropdown-link>
                    <ui-dropdown-link v-if="computed && computed['eigenvector']" @click="setNodeStyle('color', 'eigenvector')">Eigenvector centrality</ui-dropdown-link>
                </template>
            </ui-dropdown>
        </div>

        <ui-dropdown-separator class="my-4"></ui-dropdown-separator>

        <div class="text-xl">Edges</div>
        <div class="block text-sm font-medium leading-6 text-gray-900">Thickness</div>
        <ui-dropdown>
            <template v-slot:trigger>
                <a class="border border-gray-300 hover:border-gray-400 text-gray-700 hover:text-gray-900 rounded-md font-medium flex justify-between items-center h-full px-3 py-2" href="#">
                    <span class="text-gray-500 mr-1 capitalize">{{ edgeThicknessStyleOptions[edgeThicknessStyle] }}</span>
                    <ui-icon name="select" class="ml-2 "></ui-icon>
                </a>
            </template>

            <template v-slot:content>
                <ui-dropdown-link v-for="(value, key) in edgeThicknessStyleOptions" :key="key" @click="setEdgeThickness(key)">{{ value }}</ui-dropdown-link>
            </template>
        </ui-dropdown>

        <input v-if="edgeThicknessStyle == 'fixed'" type="range" min="0" max="5" step="0.5" class="w-full h-1 my-4" v-model="thicknessSlider">

        <div class="block text-sm font-medium leading-6 text-gray-900 mt-2">Color</div>
        <ui-dropdown>
            <template v-slot:trigger>
                <a class="border border-gray-300 hover:border-gray-400 text-gray-700 hover:text-gray-900 rounded-md font-medium flex justify-between items-center h-full px-3 py-2" href="#">
                    <span class="text-gray-500 mr-1">{{ edgeColorStyleOptions[edgeColorStyle] }}</span>
                    <ui-icon name="select" class="ml-2 "></ui-icon>
                </a>
            </template>

            <template v-slot:content>
                <ui-dropdown-link v-for="(value, key) in edgeColorStyleOptions" :key="key" @click="setEdgeStyle(key)">{{ value }}</ui-dropdown-link>
            </template>
        </ui-dropdown>

        <div class="mt-3" v-if="edgeColorStyle == 'fixed'">
            Pick a color:
            <div class="float-right">
                <color-picker v-model:pureColor="edgeColorPicker" />
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapState } from "pinia"
import "vue3-colorpicker/style.css"
import { ColorPicker } from "vue3-colorpicker"
import useGraphStyleStore from "@/stores/connections/graph-style"
import useGraphMetricsStore from "@/stores/connections/graph-metrics.js"

export default {
    components: {
        ColorPicker
    },

    data: () => ({
        nodeSizeByOptions: {
            'fixed': 'Fixed',
            'attribute': 'By attribute'
        },

        nodeSizeByAttrOptions: {
            'nodeDegree': 'Node degree',
            'followers': 'Followers',
            'pagerank': 'Pagerank',
            'centrality': 'Betweenness centrality',
            'eigenvector': 'Eigenvector centrality'
        },

        nodeStyleOptions: {
            'icons': 'Channel icons',
            'pictures': 'Profile pictures',
            'color': 'Color by attribute'
        },

        nodeColorAttrOptions: {
            'nodeDegree': 'Node degree',
            'followers': 'Followers',
            'subclass': 'Subclass',
            'pagerank': 'Pagerank',
            'centrality': 'Betweenness centrality',
            'eigenvector': 'Eigenvector centrality'
        },

        edgeThicknessStyleOptions: {
            'fixed': 'Fixed',
            'weight': 'By weight'
        },

        edgeColorStyleOptions: {
            'fixed': 'Fixed',
            'type': 'By type',
            'weight': 'By weight'
        }
    }),

    computed: {
        ...mapState(useGraphStyleStore, [
            'nodeStyle', 'nodeColorAttr', 'nodeSizeBy', 'nodeSize', 'nodeSizeByAttr' ,
            'edgeColor', 'edgeThickness', 'edgeColorStyle', 'edgeThicknessStyle',
        ]),

        ...mapState(useGraphMetricsStore, [ 'computed' ]),

        sizeSlider: {
            get() { return this.nodeSize },
            set(value) { this.changeNodeSize(value) }
        },

        thicknessSlider: {
            get() { return this.edgeThickness },
            set(value) { this.setEdgeThickness('fixed', value) }
        },

        edgeColorPicker: {
            get() { return this.edgeColor },
            set(value) { this.setEdgeStyle('fixed', value) }
        },
    },

    methods: {
        ...mapActions(useGraphStyleStore, [
            'setNodeStyle', 'setNodeSize', 'setEdgeStyle', 'setEdgeThickness', 'changeNodeSize'
        ])
    },

    watch: {
        sizeSlider(value) {
            this.changeNodeSize(value)
        },

        thicknessSlider(value) {
            this.setEdgeThickness('fixed', value)
        },

        edgeColorPicker(value) {
            this.setEdgeStyle('fixed', value)
        }
    }
}
</script>

<style>
.vc-color-wrap {
    border-radius: 10px;
    margin-right: 0!important;
}
</style>
