import useMyChannelListsStore from '@/stores/me/channel-lists'
import useMyCuratedListsStore from '@/stores/me/curated-lists'

import defineCards from '@/stores/reusable/cards'

import { asyncResource, channels } from '@/api'

import debounce from 'just-debounce-it'
import { defineStore } from 'pinia'

export const useIndexCardsListsListStore = defineCards({
    id: 'channelsIndexCardsListsList',

    source: defineStore({
        id: 'channelsIndexCardsListsListSource',
        
        state: () => ({
            params: {},
            paginated: true,
            
            resource: asyncResource({
                method: 'post',
                request: (api, store, payload) => channels()
                    .filters({
                        ...store.filters,
                        list: store.params.id,
                        name: { query: store.searchQuery }
                    })
                    .query(payload)
                    .sorting(store.sorting)
                    .toRequest(),
                paginated: true
            }),

            searchQuery: '',
            sorting: 'name-asc',
            filters: {}
        }),
        
        getters: {
            items(store) {
                return store.resource.data || []
            },

            isLoading(store) {
                return store.resource.isFetchingFirst
            }
        },

        actions: {
            initialize(params) {
                this.params = params
                this.resource.reset()
                this.resource.fetchFirst(this)
            },

            reload() {
                this.resource.reset()
                this.resource.fetchFirst(this)
            },

            async loadMore(infiniteScroll) {
                let items = await this.resource.fetchNext(this)

                items.length ? infiniteScroll.loaded() : infiniteScroll.complete()
            },

            filterBy: debounce(function(filters) {
                this.filters = filters
                this.reload()
            }, 250),

            sortBy(sorting) {
                this.sorting = sorting
                this.reload()
            },

            search: debounce(function (query) {
                this.searchQuery = query
                this.reload()
            }, 250)
        }
    }),
    layout: 'table',

    searchable: true,
    filterable: true,

    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', api: 'name-asc', default: true
        },
        {
            id: 'followers', name: 'Followers', icon: 'users', api: 'followers-desc'
        }
    ],

    getters: {
        list: store => useMyChannelListsStore().find(store.params.id) || useMyCuratedListsStore().findList(store.params.id)
    }
})

export default useIndexCardsListsListStore
