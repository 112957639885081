<template>
    <div class="bg-white/80 backdrop-blur-sm border border-gray-200 rounded-md p-2 min-w-64" v-if="nodeSizeBy == 'attribute' && nodeSizeDomain">
        <div class="flex justify-between">
            <div class="mb-2">Size by:</div>
            <div class="capitalize ml-2 font-bold">{{ nodeSizeByAttr }}</div>
        </div>

        <div>
            <div class="mt-0.5">
                <div class="circle-wrapper"><div id="circle1" class="circle"></div></div>
                <div class="circle-wrapper"><div id="circle2" class="circle"></div></div>
                <div class="circle-wrapper"><div id="circle3" class="circle"></div></div>
                <div class="circle-wrapper"><div id="circle4" class="circle"></div></div>
                <div class="circle-wrapper"><div id="circle5" class="circle"></div></div>
            </div>
            <div class="clear-both"></div>

            <div class="flex justify-between mt-1">
                <div>{{ nodeSizeDomain[0] % 1 === 0 ? $number(nodeSizeDomain[0]) : $number(nodeSizeDomain[0], ".4g") }}</div>
                <div>{{ nodeSizeDomain[1] % 1 === 0 ? $number(nodeSizeDomain[1]) : $number(nodeSizeDomain[1], ".4g") }}</div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from "pinia";
import useGraphStyleStore from "@/stores/connections/graph-style.js";

export default {
    props: {
        store: Object,
    },

    computed: {
        ...mapState(useGraphStyleStore, [ 'nodeSizeBy', 'nodeSizeByAttr', 'nodeSizeDomain' ]),
    },
}
</script>

<style>
.circle-wrapper {
    width: 20%;
    height: 25px;
    text-align: center;
    vertical-align: center;
    float: left;
}

.circle {
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    background: gray;
    margin-left: auto;
    margin-right: auto;
}

#circle1 { width: 5px; height: 5px; margin-top: 10px }
#circle2 { width: 10px; height: 10px; margin-top: 7px }
#circle3 { width: 15px; height: 15px; margin-top: 5px }
#circle4 { width: 20px; height: 20px; margin-top: 2px }
#circle5 { width: 25px; height: 25px; margin-top: -1px }
</style>