import { defineContentKeywordsAnalysisStore } from '@/stores/analysis/analyses/content-keywords'

import { defineStore } from 'pinia'

export const defineOverviewKeywordsStore = settings => defineStore({
    id: settings.id,

    state: () => ({
        series: [],

        isInitialized: false
    }),

    getters: {
        isComparing: store => store.series.length > 1
    },

    actions: {
        async initialize(series) {
            this.series = [{ ...series, analysisStore: defineContentKeywordsAnalysisStore({ id: `${settings.id}Analysis` }) }]

            await this.load()

            this.isInitialized = true
        },

        compareWith(series) {
            this.series.push(series = { ...series, analysisStore: defineContentKeywordsAnalysisStore({ id: `${settings.id}Analysis${this.series.length}` }) })

            this.loadAnalysis(series.analysisStore(), series.filters)
        },

        stopComparing(series) {
            this.series.find(s => s.id == series.id)?.analysisStore().$dispose()
            this.series = this.series.filter(s => s.id != series.id)
        },

        async load() {
            return Promise.all(this.series.map(series => this.loadAnalysis(series.analysisStore(), series.filters)))
        },

        loadAnalysis(store, filters) {
            filters = filters.toPerspective()

            store.series = [{
                datasetType: 'inline-perspective',
                datasetFilters: JSON.stringify(filters),
                meta: { metric: 'interactions' },
                values: []
            }]

            if (filters['date']) {
                store.date = filters['date']
            } else {
                store.date = { type: 'past', date: { past: 5, unit: 'years' } }
            }

            return store.loadSeries()
        }
    }
})

export default defineOverviewKeywordsStore
