<template>
    <div class="p-2 float-right flex">
        <div class="inline-block w-40 mr-2">
            <ui-input icon="search" size="lg" type="search" placeholder="Search in graph" v-model="searchQuery"></ui-input>
        </div>

        <ui-dropdown class="inline-block mr-2" width="w-auto" >
            <template v-slot:trigger>
                <ui-button size="lg" :disabled="!store.selectedNodes.length" class="w-full h-10"  icon="chevron-down">Selected actions</ui-button>
            </template>

            <template v-slot:content>
                <ui-dropdown-link icon="square" @click="store.selectNodes([], true)">Deselect</ui-dropdown-link>
                <ui-dropdown-link icon="share" @click="store.inspectNode(store.selectedNodes)" v-if="store.selectedNodes.length < 4">
                    Load connections for selected
                </ui-dropdown-link>
                <ui-dropdown-link icon="eye" @click="store.showNode(store.selectedNodes)">Show selected nodes</ui-dropdown-link>
                <ui-dropdown-link icon="eye-off" @click="store.hideNode(store.selectedNodes)">Hide selected nodes</ui-dropdown-link>
                <ui-dropdown-link icon="hash" @click="store.freezeNode(store.selectedNodes)">Freeze selected nodes</ui-dropdown-link>
                <ui-dropdown-link icon="trash" @click="deleteSelected" v-if="store.selectedNodes.length <= 100">Delete selected nodes</ui-dropdown-link>
            </template>
        </ui-dropdown>

        <ui-dropdown class="inline-block mr-2" width="w-auto">
            <template v-slot:trigger>
                <ui-button size="lg" class="w-full"  icon="chevron-down">Export</ui-button>
            </template>

            <template v-slot:content>
                <ui-dropdown-link icon="file-text" @click="exportAs('csv')">Export nodes as .csv</ui-dropdown-link>
                <ui-dropdown-link icon="share" @click="exportAs('gexf')">Export as .gexf</ui-dropdown-link>
            </template>
        </ui-dropdown>
    </div>

    <div class="ml-2">
        <div class="border-b border-gray-200">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                <a @click="shownTable = 'nodes'" class="px-8 cursor-pointer" :class="[shownTable === 'nodes' ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium']" :aria-current="shownTable === 'nodes' ? 'page' : undefined">Nodes ({{ nodes.length }})</a>
                <a @click="shownTable = 'edges'" class="px-8 cursor-pointer" :class="[shownTable === 'edges' ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium']" :aria-current="shownTable === 'edges' ? 'page' : undefined">Edges ({{ edges.length }})</a>
            </nav>
        </div>
    </div>

    <div class="h-full w-full" :style="{ height: windowHeight + 'px' }">
        <ag-grid-vue
            v-if="shownTable === 'nodes'"
            style="height: 100%"
            class="ag-theme-quartz"

            :rowData="nodes"
            :columnDefs="nodeHeaders"
            :rowSelection="{ mode: 'multiRow', selectAll: 'filtered' }"
            :onRowSelected="onRowSelected"
            :quickFilterText="searchQuery"
            :gridOptions="gridOptions"

            @gridReady="onGridReady"
            @selectionChanged="selectionChanged"

        >
        </ag-grid-vue>

        <ag-grid-vue
            v-if="shownTable === 'edges'"
            style="height: 100%"
            class="ag-theme-quartz"

            :rowData="edges"
            :columnDefs="edgeHeaders"
            :quickFilterText="searchQuery"
            :gridOptions="gridOptions"
        >
        </ag-grid-vue>
    </div>
</template>

<script>

import { mapActions, mapState } from "pinia"
import { AgGridVue } from "ag-grid-vue3"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-quartz.css"
import useConnectionsDataStore from "@/stores/connections/connections-data.js"


export default {
    components: {
        AgGridVue
    },

    props: {
        store: Object
    },

    data: () => ({
        shownTable: 'nodes',
        windowHeight: window.innerHeight - 110,
        searchQuery: '',

        gridOptions: {
            columnTypes: {
                number: {
                    filter: 'agNumberColumnFilter',
                    // valueFormatter: params => (params.value ? params.value.toFixed(2) : ''),
                },
            },
        },
    }),

    computed: {
        ...mapState(useConnectionsDataStore, [ 'nodeHeaders', 'edgeHeaders' ]),

        nodes() {
            return this.store.nodes.filter(n => (n.attributes.class !== 'Content')).map(n => ({ ...n.attributes }))
        },

        edges() {
            return this.store.edges
        }
    },

    methods: {
        ...mapActions(useConnectionsDataStore, [
            'checkSelectedNodes', 'onGridReady', 'exportAs', 'onRowSelected', 'selectionChanged',
            'quickSearch', 'quickFilterParser', ''
        ]),

        resizeWindow() {
            this.windowHeight = window.innerHeight - 110
        },

        searchNodes() {
            this.quickSearch(this.searchQuery)
        },

        deleteSelected() {
            this.store.deleteNode(this.store.selectedNodes)
            this.store.selectNodes([], true)
        }
    },

    created() {
        window.addEventListener("resize", this.resizeWindow);
    },

    destroyed() {
        window.removeEventListener("resize", this.resizeWindow);
    }
};
</script>
