import { defineStore } from 'pinia'
import defineConnectionsGraph from "@/stores/connections/connections-graph.js"
import {
    interpolateSizeByAttr,
    interpolateColorByAttr,
    interpolateEdgeColorByAttr,
    interpolateEdgeColorByWeight,
    interpolateEdgeSizeByWeight
} from "@/stores/connections/utils.js"
import { node_styles } from "@/components/connections/node_styles.js"
import useGraphFiltersStore from "@/stores/connections/graph-filters.js"

const useGraphStyleStore = defineStore({
    id: 'graph-style',

    state: () => ({
        graph: null,
        renderer: null,

        inspectedNodeIds: defineConnectionsGraph({ id: 'connectionsGraph' })().inspectedNodeIds,
        edgesInGraph: useGraphFiltersStore().edgesInGraph,

        nodeStyles: node_styles,

        nodeStyle: 'icons',

        nodeColorAttr: 'followers',
        nodeColorFunction: null,
        nodeColorByNumeric: false,
        nodeColorDomain: null,

        nodeSize: 10,
        nodeSizeBy: 'fixed',
        nodeSizeByAttr: 'followers',
        nodeSizeFunction: null,
        nodeSizeDomain: null,

        edgeColorStyle: 'fixed',
        edgeColor: '#677f8c',
        edgeThicknessStyle: 'weight',
        edgeThickness: 2,
    }),

    actions: {
        initialize(graph, renderer) {
            this.graph = graph
            this.renderer = renderer
        },

        reset() {
            this.nodeStyle = 'icons'

            this.nodeColorAttr = 'followers'
            this.nodeColorFunction = null
            this.nodeColorByNumeric = false
            this.nodeColorDomain = null

            this.nodeSize = 10
            this.nodeSizeBy = 'fixed'
            this.nodeSizeByAttr = 'followers'
            this.nodeSizeFunction = null
            this.nodeSizeDomain = null

            this.edgeColorStyle = 'fixed'
            this.edgeColor = '#677f8c'
            this.edgeThicknessStyle = 'weight'
            this.edgeThickness = 2
        },

        getSettings() {
            return {
                nodeStyle: this.nodeStyle,

                nodeColorAttr: this.nodeColorAttr,

                nodeSize: this.nodeSize,
                nodeSizeBy: this.nodeSizeBy,
                nodeSizeByAttr: this.nodeSizeByAttr,

                edgeColor: this.edgeColor,
                edgeColorStyle: this.edgeColorStyle,

                edgeThicknessStyle: this.edgeThicknessStyle,
                edgeThickness: this.edgeThickness,
            }
        },

        setSettings(settings) {
            this.nodeStyle = settings.nodeStyle || this.nodeStyle

            this.nodeColorAttr = settings.nodeColorAttr || this.nodeColorAttr

            this.nodeSize = settings.nodeSize || this.nodeSize
            this.nodeSizeBy = settings.nodeSizeBy || this.nodeSizeBy
            this.nodeSizeByAttr = settings.nodeSizeByAttr || this.nodeSizeByAttr

            this.edgeColor = settings.edgeColor || this.edgeColor
            this.edgeColorStyle = settings.edgeColorStyle || this.edgeColorStyle

            this.edgeThicknessStyle = settings.edgeThicknessStyle || this.edgeThicknessStyle
            this.edgeThickness = settings.edgeThickness || this.edgeThickness
        },

        applyStyles() {
            this.setNodeStyle(this.nodeStyle == 'pictures' ? 'icons' : this.nodeStyle, this.nodeColorAttr)
            this.setNodeSizeByAttr(this.nodeSizeByAttr)

            this.setEdgeStyle(this.edgeColorStyle, this.edgeColor)
            this.setEdgeThickness(this.edgeThicknessStyle, this.edgeThickness)
        },

        setNodeStyle(style, attr) {
            this.nodeStyle = style

            switch (style) {
                case 'color':
                    this.setNodeColor(attr ? attr : this.nodeColorAttr)
                    break

                case 'pictures':
                    this.graph.forEachNode((n, a) => {
                        if (a.avatar) {
                            a.image = a.avatar
                        } else {
                            a.image = a.icon
                        }
                    })
                    break

                case 'icons':
                    this.graph.forEachNode((n, a) => {
                        a.color = this.nodeStyles[a.object_type].color
                        a.image = a.icon
                    })
                    break

                default:
                    break
            }

            this.renderer.refresh()
        },

        setNodeColor(attr) {
            const { colorFunction, domain, numeric } = interpolateColorByAttr(this.graph, attr)

            this.nodeColorAttr = attr
            this.nodeColorDomain = domain
            this.nodeColorFunction = colorFunction
            this.nodeColorByNumeric = numeric

            if (this.nodeStyle !== 'color') return

            this.graph.forEachNode((n, a) => {
                if (a[attr]) {
                    a.color = colorFunction(a[attr])
                    a.zIndex = 99
                } else {
                    a.color = 'rgba(128, 128, 128, 0.6)'
                    a.zIndex = 1
                }
                a.image = null
            })
        },

        setNodeSizeByAttr(attr) {
            const { sizeFunction, domain, numeric } = interpolateSizeByAttr(this.graph, attr)

            this.nodeSizeByAttr = attr
            this.nodeSizeBy = 'attribute'
            this.nodeSizeDomain = domain
            this.nodeSizeFunction = sizeFunction
            // this.sizeByNumeric = numeric

            this.graph.forEachNode((n, a) => {
                if (a[attr]) {
                    a.size = sizeFunction(a[attr])
                } else {
                    a.size = 5
                }
            })

            this.renderer.refresh()
        },

        setEdgeThickness(type, attr) {
            this.edgeThicknessStyle = type

            let sizeFunc = null

            switch (type) {
                case 'fixed':
                    this.edgeThickness = parseInt(attr)
                    this.graph.forEachEdge((e, a) => { a.size = this.edgeThickness })
                    this.renderer.refresh()
                    break

                case 'weight':
                    sizeFunc = interpolateEdgeSizeByWeight(this.graph)
                    this.graph.forEachEdge((e, a) => { a.size = sizeFunc(a.weight) })
                    this.renderer.refresh()

                    break
            }
        },

        setEdgeStyle(style, attr) {
            this.edgeColorStyle = style
            this.edgeColor = attr || this.edgeColor

            let colorFunc = null
            switch (style) {
                case 'fixed':
                    this.graph.forEachEdge((e, a) => {
                        a.color = attr
                    })
                    this.renderer.refresh()
                    break

                case 'type':
                    colorFunc = interpolateEdgeColorByAttr(this.edgesInGraph)
                    this.graph.forEachEdge((e, a) => {
                        a.color = colorFunc(a.label)
                    })
                    this.renderer.refresh()
                    break

                case 'weight':
                    colorFunc = interpolateEdgeColorByWeight(this.graph)
                    this.graph.forEachEdge((e, a) => {
                        a.color = colorFunc(a.weight)
                    })
                    this.renderer.refresh()
                    break
            }
        },

        setNodeSize(sizeBy, attr) {
            this.nodeSizeBy = sizeBy
            this.nodeSizeByAttr = attr || this.nodeSizeByAttr

            switch (sizeBy) {
                case 'fixed':
                    this.changeNodeSize(this.nodeSize)
                    break

                case 'attribute':
                    this.setNodeSizeByAttr(this.nodeSizeByAttr)
                    break
            }
        },

        changeNodeSize(size = null) {
            this.nodeSize = size ? parseInt(size) : this.nodeSize

            this.graph.forEachNode((n, a) => {
                a.size = this.nodeSize
            })

            this.renderer.refresh()
        },

        getNodeStyles(node) {
             return this.nodeStyles[node.attributes.object_type] || this.nodeStyles['default']
        }
    }
})

export default useGraphStyleStore
