import { defineStore } from 'pinia'
import { markRaw } from "vue";
import defineConnectionsGraph from "@/stores/connections/connections-graph.js";
import useConnectionsModalsNodeDetailsStore from "@/stores/connections/modals/node-details.js";


const useGraphEventsStore = defineStore({
    id: 'graph-events',

    state: () => ({
        graphStore: null,

        graph: null,
        renderer: null,

        selectionModeEnabled: false,
        startX: 0, startY: 0, endX: 0, endY: 0,

        selectionRectangle: null,

        menuX: 0,
        menuY: 0,
        contextMenuShown: false,
        selectedNode: null,

        dragModeEnabled: false,
        isDragging: false,
        draggedNode: null,
    }),

    actions: {
        initialize(graph, renderer, container) {
            this.graph = graph
            this.renderer = renderer

            this.graphStore = defineConnectionsGraph({ id: 'connectionsGraph' })()

            // Context menu
            this.renderer.on('clickNode', event => this.clickNode(event))
            this.renderer.on('rightClickNode', this.showContextMenu)

            // Selection mode
            this.selectionRectangle = markRaw(document.getElementById('selection-rectangle'))

            container.addEventListener('mousedown', this.selectionMouseDown)
            container.addEventListener('mousemove', this.selectionMouseMove)
            container.addEventListener('mouseup', this.selectionMouseUp)
            this.selectionRectangle.addEventListener('mouseup', this.selectionMouseUp)

            // // Drag mode
            this.renderer.on("downNode", this.dragDownNode)
            this.renderer.getMouseCaptor().on("mousemovebody", this.dragMouseMove)
            this.renderer.getMouseCaptor().on("mouseup", this.dragMouseUp)
            this.renderer.getMouseCaptor().on("mousedown", this.dragMouseDown)

            document.addEventListener('keydown', this.keyDown)
        },

        selectionMouseDown(e) {
            if (!this.selectionModeEnabled) return

            e.preventDefault()

            this.startX = markRaw(e.clientX)
            this.startY = markRaw(e.clientY)
            this.selectionRectangle.style.display = 'block'
            this.selectionRectangle.style.width = '0px'
            this.selectionRectangle.style.height = '0px'
            this.selectionRectangle.style.left = `${this.startX}px`
            this.selectionRectangle.style.top = `${this.startY}px`
            this.isDragging = true
        },

        selectionMouseMove(e) {
            if (!this.selectionModeEnabled) return
            if (!this.isDragging) return
            e.preventDefault()

            this.endX = e.clientX
            this.endY = e.clientY
            this.selectionRectangle.style.left = `${Math.min(this.startX, this.endX)}px`
            this.selectionRectangle.style.top = `${Math.min(this.startY, this.endY)}px`
            this.selectionRectangle.style.width = `${Math.abs(this.endX - this.startX)}px`
            this.selectionRectangle.style.height = `${Math.abs(this.endY - this.startY)}px`
        },

        selectionMouseUp(e) {
            if (!this.selectionModeEnabled) return

            // e.preventDefault()
            if (!this.isDragging) return
            this.isDragging = false
            this.selectionRectangle.style.display = 'none'

            if (this.selectionRectangle.style.width !== '0px' || this.selectionRectangle.style.height !== '0px') {
                this.selectNodesInRectangle()
            }

            this.selectionRectangle.style.width = '0px'
            this.selectionRectangle.style.height = '0px'
            this.endX = 0
            this.endY = 0
        },

        selectNodesInRectangle() {
            const selectedNodes = []

            const tweakX = 57
            const tweakY = 66

            const minX = Math.min(this.startX, this.endX) - tweakX
            const minY = Math.min(this.startY, this.endY) - tweakY
            const maxX = Math.max(this.startX, this.endX) - tweakX
            const maxY = Math.max(this.startY, this.endY) - tweakY

            this.graph.forEachNode((node, attr) => {
                const nodeDisplayData = this.renderer.getNodeDisplayData(node)
                const nodeScreenCoords = this.renderer.framedGraphToViewport({ x: nodeDisplayData.x, y: nodeDisplayData.y })

                if (
                    nodeScreenCoords.x >= minX &&
                    nodeScreenCoords.x <= maxX &&
                    nodeScreenCoords.y >= minY &&
                    nodeScreenCoords.y <= maxY
                ) {
                    selectedNodes.push(node)
                }
            })

            this.graphStore.selectNodes(selectedNodes, true)
            this.renderer.refresh()
        },

        toggleSelectionMode() {
            if (this.dragModeEnabled) {
                this.toggleDragMode()
            }

            this.selectionModeEnabled = !this.selectionModeEnabled

            if (this.selectionModeEnabled) {
                document.getElementById('connections-graph-container').style.cursor = 'crosshair'
                this.renderer.getMouseCaptor().enabled = false
            } else {
                this.isDragging = false
                this.selectionRectangle.style.display = 'none'

                this.selectionRectangle.style.width = '0px'
                this.selectionRectangle.style.height = '0px'
                this.endX = 0
                this.endY = 0

                document.getElementById('connections-graph-container').style.cursor = this.dragModeEnabled ? 'move' : 'grab'
                this.renderer.getMouseCaptor().enabled = true
            }
        },

        // Drag mode
        toggleDragMode() {
            if (this.selectionModeEnabled) {
                this.toggleSelectionMode()
            }

            this.dragModeEnabled = !this.dragModeEnabled

            if (this.dragModeEnabled) {
                document.getElementById('connections-graph-container').style.cursor = 'move'
            }   else {
                document.getElementById('connections-graph-container').style.cursor = 'grab'
            }
        },

        dragDownNode(e) {
            if (!this.dragModeEnabled) return
            this.isDragging = true
            this.draggedNode = e.node
            this.graph.setNodeAttribute(this.draggedNode, "highlighted", true)
        },

        dragMouseMove(e) {
            if (!this.dragModeEnabled) return
            if (!this.isDragging || !this.draggedNode) return

            // Get new position of node
            const pos = this.renderer.viewportToGraph(e)

            this.graph.setNodeAttribute(this.draggedNode, "x", pos.x)
            this.graph.setNodeAttribute(this.draggedNode, "y", pos.y)

            // Prevent sigma to move camera:
            e.preventSigmaDefault()
            e.original.preventDefault()
            e.original.stopPropagation()
        },

        dragMouseUp() {
            if (!this.dragModeEnabled) return

            if (this.draggedNode) {
                this.graph.removeNodeAttribute(this.draggedNode, "highlighted")
            }

            this.isDragging = false
            this.draggedNode = null
        },

        dragMouseDown() {
            if (!this.dragModeEnabled) return
            if (!this.renderer.getCustomBBox()) this.renderer.setCustomBBox(this.renderer.getBBox())
        },

        keyDown(e) {
            if (e.key === 'Escape' ) {
                if (this.selectionModeEnabled) {
                    this.toggleSelectionMode()
                }

                if (this.dragModeEnabled) {
                    this.toggleDragMode()
                }
            }
        },

        showContextMenu(e) {
            e.event.original.preventDefault()

            this.menuX = e.event.x + 50
            this.menuY = e.event.y + 50
            this.contextMenuShown = true
            this.selectedNode = this.graph.getNodeAttributes(e.node)
        },

        hideContextMenu() {
            this.contextMenuShown = false
            this.selectedNode = null
        },

        clickNode(event) {
            if (useGraphEventsStore().dragModeEnabled) return;

            if (event.event.original.altKey) {
                this.debugNode(event.node)
            } else {
                let attributes = this.graph.getNodeAttributes(event.node)
                useConnectionsModalsNodeDetailsStore().open(attributes.class, attributes.node_id)
            }
        }
    }
})

export default useGraphEventsStore
