<template>
    <div class="relative max-w-12xl mx-auto px-6 xl:px-8">
        <stream-toolbar-results :store="$page.streamStore"></stream-toolbar-results>

        <ui-cards :store="$page.streamStore">
            <template v-slot:item="{ item }">
                <content-card :content="item" :stream-store="$page.streamStore" :key="item.id"></content-card>
            </template>
        </ui-cards>

        <div v-if="$page.streamStore.items.error">
            <div class="h-96 flex flex-col items-center justify-center text-gray-600">
                <ui-icon name="frown" class="text-6xl text-gray-500"></ui-icon>
                <div class="text-base font-semibold mt-3 mb-2">Ooops, something went wrong!</div>
                <div class="text-center text-sm">
                    <p>Looks like we are experiencing some technical issues (sorry about that).</p>
                    <p>Please try again later, if the problem persists, please <a class="underline" href="mailto:support@gerulata.com">contact us</a> for further assistance.</p>
                </div>
            </div>
        </div>

        <div v-if="! $page.streamStore.items.data?.length && ! $page.streamStore.items.pending && $page.streamStore.isInitialized">
            <div class="h-96 flex flex-col items-center justify-center text-gray-600">
                <ui-icon name="search" class="text-6xl text-gray-500"></ui-icon>
                <div class="text-base font-semibold mt-3 mb-2">We didn't find any results.</div>
                <div class="text-center text-sm">
                    <p>Check your spelling and try again, or try a different set of filters.</p>
                </div>
            </div>
        </div>

        <ui-infinite-loading class="absolute invisible" :style="{ bottom: windowInnerHeight * 2 + 'px' }" :identifier="$page.streamStore.lastReloadTime" @infinite="$page.streamStore.loadMore($event)" v-if="$page.streamStore.items.data && $page.streamStore.items.data.length"></ui-infinite-loading>
        <ui-infinite-loading class="mt-12 mb-8" :identifier="$page.streamStore.lastReloadTime" @infinite="$page.streamStore.loadMore($event)" v-if="$page.streamStore.items.data && $page.streamStore.items.data.length"></ui-infinite-loading>

        <Teleport to="#root">
            <stream-lightbox :store="$page.streamStore.mediaLightbox"></stream-lightbox>
        </Teleport>
    </div>
</template>

<script>
import ContentCard from '@/components/content/stream-cards/content'
import StreamLightbox from './stream/lightbox'
import StreamToolbarResults from './stream/toolbar'

export default {
    components: {
        ContentCard, StreamLightbox, StreamToolbarResults
    },

    computed: {
        windowInnerHeight() {
            return window.innerHeight
        }
    }
}
</script>
