<template>
    <ui-contextual-menu>
        <ui-dropdown-link icon="pin-off" @click="updatePerspective(perspective, { pinned: 1 })" v-if="! perspective.pinned">
           Pin
        </ui-dropdown-link>
        <ui-dropdown-link icon="pin-off" @click="updatePerspective(perspective, { pinned: 0 })" v-if="perspective.pinned">
           Un-pin
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="settings" @click="editPerspective(perspective)">
           Settings
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="copy" @click="duplicatePerspective(perspective)">
            Duplicate
        </ui-dropdown-link>

        <ui-dropdown-link icon="trash" @click="deletePerspective(perspective)">
            Delete
        </ui-dropdown-link>

        <template v-if="$can('manage-world')">
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="wrench" @click="$inspect(perspective)">
                Debug
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
import useContentModalsPerspectiveDuplicateStore from '@/stores/content/modals/perspective-duplicate'
import useContentModalsPerspectiveEditStore from '@/stores/content/modals/perspective-edit'
import useMyPerspectivesStore from '@/stores/me/perspectives'

import { mapActions, mapStores } from 'pinia'

export default {
    props: [ 'perspective' ],

    computed: {
        ...mapStores(useMyPerspectivesStore)
    },

    methods: {
        ...mapActions(useMyPerspectivesStore, {
            'deletePerspective': 'delete',
            'updatePerspective': 'update'
        }),

        ...mapActions(useContentModalsPerspectiveEditStore, { 'editPerspective': 'open' }),

        ...mapActions(useContentModalsPerspectiveDuplicateStore, { 'duplicatePerspective': 'open' })
    }
}
</script>
