<template>
    <ui-contextual-menu plain>
        <ui-dropdown-link :disabled="! isLanguageTranslatable(content.language)" icon="translate" v-on="isLanguageTranslatable(content.language) ? { click: () => translate(content) } : {}">
            Translate text
        </ui-dropdown-link>

<!--        <ui-dropdown-link :href="$router.resolve({ name: 'connections.content', params: { id: content.id } }).href" icon="compass" target="_blank">-->
<!--            Trace content-->
<!--        </ui-dropdown-link>-->

        <ui-dropdown-separator></ui-dropdown-separator>

        <channel-section v-if="content.publishedBy" inline icon="arrow-up-circle" name="Published by..." :channel="content.publishedBy" type="publishedBy" :stream-store="streamStore"></channel-section>
        <channel-section v-if="content.publishedInto.id != content.publishedBy?.id" inline icon="arrow-down-circle" name="Published in..." :channel="content.publishedInto" type="publishedInto" :stream-store="streamStore"></channel-section>
        <tags-dropdown align="right" :item="content"></tags-dropdown>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="info" :href="$router.resolve({ name: 'content.details', params: { id: content.id } }).href" target="_blank">
            Show details
        </ui-dropdown-link>

        <ui-dropdown-link icon="link" @click="copyLink">
            Copy link
        </ui-dropdown-link>

        <ui-dropdown-link icon="external-link" :href="content.link" target="_blank" rel="noreferrer noopener" :disabled="! content.link">
            Open original
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="edit" @click="() => createNote(content)">
            Add note...
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="warning" @click="() => report('content', content.id)">
            Report
        </ui-dropdown-link>

        <template v-if="$can('manage-world')">
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="wrench" @click="$inspect(content)">
                Debug
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
import TagsDropdown from '@/components/me/tags/contextual-menu'
import ChannelSection from '@/components/channels/contextual-menus/channel'

import useMeModalsNoteStore from '@/stores/me/modals/note'
import useSupportReportStore from '@/stores/support/report'

import { isLanguageTranslatable, translate as _translate } from '@/helpers/translations'

import { mapActions } from 'pinia'

export default {
    components: { ChannelSection, TagsDropdown },

    props: [ 'content', 'streamStore' ],

    methods: {
        isLanguageTranslatable,

        async translate(content) {
            _translate(content, {})
                .then(() => this.streamStore.triggerLayoutUpdate())

            this.$nextTick(() => this.streamStore.triggerLayoutUpdate())
        },

        copyLink() {
            navigator.clipboard.writeText(
                window.location.origin + this.$router.resolve({ name: 'content.details', params: { id: this.content.id } }).href
            )
        },

        ...mapActions(useMeModalsNoteStore, { createNote: 'create' }),
        ...mapActions(useSupportReportStore, [ 'report' ])
    }
}
</script>
