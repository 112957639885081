<template>
    <div class="p-4">
        <div class="block text-sm font-medium leading-6 text-gray-900">Algorithm:</div>
        <ui-dropdown>
            <template v-slot:trigger>
                <a class="border border-gray-300 hover:border-gray-400 text-gray-700 hover:text-gray-900 rounded-md font-medium flex justify-between items-center h-full px-3 py-2" href="#">
                    <span class="text-gray-500 mr-1">{{ layoutTypeOptions[layoutType] }}</span>
                    <ui-icon name="select" class="ml-2 "></ui-icon>
                </a>
            </template>

            <template v-slot:content>
                <ui-dropdown-link icon="globe" @click="applyLayout('force-atlas', { gravity, linLogMode })">Force atlas2</ui-dropdown-link>
                <ui-dropdown-link icon="target" @click="applyLayout('circular')">Circular</ui-dropdown-link>
                <ui-dropdown-link icon="help-circle" @click="applyLayout('random')">Random</ui-dropdown-link>
                <ui-dropdown-link icon="git-pull-request" @click="applyLayout('noverlap')">Noverlap</ui-dropdown-link>
            </template>
        </ui-dropdown>

        <div class="text-sm mt-3 text-center">
            <span v-if="layoutType == 'force-atlas'">Reveals clusters and relationships, making community structures and key connections easy to spot.</span>
            <span v-if="layoutType == 'random'">Nodes are placed randomly without any specific structure or pattern.</span>
            <span v-if="layoutType == 'circular'">Nodes are arranged in a circle, equally spaced for a clear, symmetric view.</span>
            <span v-if="layoutType == 'noverlap'">Adjusts node positions to prevent them from overlapping while maintaining the overall layout structure.</span>
        </div>

        <div v-if="layoutType == 'force-atlas'">
            <ui-dropdown-separator class="my-4"></ui-dropdown-separator>
            <div class="flex justify-between items-center">
                <div>Gravity:</div>
                <div class="w-20">
                    <ui-input type="number" class="w-full" min="0" max="10" v-model="gravity"></ui-input>
                </div>
            </div>

            <div class="flex justify-between items-center mt-4">
                <div>Lin-log mode:</div>
                <ui-switch v-model="linLogMode"></ui-switch>
            </div>
        </div>

        <ui-dropdown-separator class="my-4"></ui-dropdown-separator>

        <div class="grid grid-cols-2 gap-2 mt-2" v-if="['force-atlas', 'noverlap'].includes(layoutType)">
            <ui-button class="w-full" color="blue" icon="play" @click="runLayout({ gravity, linLogMode })" v-if="animationTimer == null">Run</ui-button>
            <ui-button class="w-full" color="blue" icon="x" @click="stopLayout" v-if="animationTimer != null">Stop</ui-button>
            <ui-button class="w-full" color="blue" icon="chevrons-right" @click="stepLayout({ gravity, linLogMode })" :disabled="animationTimer != null">Step</ui-button>
        </div>

        <div class="mt-2" v-else>
            <ui-button class="w-full" color="blue" icon="play" @click="runLayout">Apply</ui-button>
        </div>
    </div>
</template>


<script>

import { mapActions, mapState } from "pinia"
import UiButton from "@/components/ui/button.vue"
import useGraphLayoutStore from "@/stores/connections/graph-layout.js"


export default {
    components: { UiButton },

    data: () => ({
        layoutType: "force-atlas",
        gravity: 1.005,
        linLogMode: true,

        layoutTypeOptions: {
            'force-atlas': 'Force atlas2',
            'circular': 'Circular',
            'noverlap': 'Noverlap',
            'random': 'Random'
        }
    }),

    computed: {
        ...mapState(useGraphLayoutStore, ['animationTimer']),
    },

    methods: {
        ...mapActions(useGraphLayoutStore, [ 'runLayout', 'stopLayout', 'stepLayout' ]),

        applyLayout(type) {
            this.layoutType = type
            useGraphLayoutStore().layout(type)
        }
    },

    watch: {
        gravity(value) {
            if (value < 0) {
                this.gravity = 0
            }
        }
    }
}
</script>
