<template>
    <router-link :to="{ name: 'analysis.analysis.details', params: { type: analysis.type, id: analysis.id } }" v-if="mode == 'card'">
        <div class="rounded-lg bg-white border border-gray-100 shadow hover:shadow-lg cursor-pointer relative group">
            <div class="flex items-center relative p-4">
                <ui-avatar :item="analysis" class="w-8 h-8 shrink-0"></ui-avatar>

                <div class="flex-1 min-w-0 px-3">
                    <h1 class="text-xl font-semibold truncate leading-tight" v-tooltip="tooltip">
                        {{analysis.name}}
                    </h1>
                    <div class="text-gray-800 text-xs truncate leading-tight">
                        {{type?.name}} Analysis
                    </div>
                </div>

                <div class="shrink-0">
                    <contextual-menu :analysis="analysis" plain></contextual-menu>
                </div>
            </div>

            <div class="flex items-center relative px-4 h-8 bg-gray-50 border-t border-gray-75 rounded-b space-x-3 text-xs text-gray-500" v-if="analysis.recent || analysis.pinned">
                <span class="flex items-center space-x-1" v-if="analysis.recent">
                    <ui-icon name="time"></ui-icon>
                    <span>Recent</span>
                </span>

                <span class="flex items-center space-x-1" v-if="analysis.pinned">
                    <ui-icon name="pin-off"></ui-icon>
                    <span>Pinned</span>
                </span>
            </div>
        </div>
    </router-link>

    <component :is="cardsStore.chooseCallback ? 'div' : 'router-link'" :to="{ name: 'analysis.analysis.details', params: { type: analysis.type, id: analysis.id } }" class="block hover:bg-gradient-to-r from-transparent via-gray-50 to-transparent transition" v-else-if="mode == 'row'">
        <div class="flex h-12 group relative">
            <div class="pl-2 flex items-center flex-1 min-w-0">
                <ui-avatar :item="analysis" class="w-7 h-7 drop-shadow-sm shrink-0"></ui-avatar>

                <div class="min-w-0 px-2">
                    <h1 class="text-xl font-semibold truncate leading-tight" v-tooltip="tooltip">
                        {{analysis.name}}
                    </h1>
                </div>

                <div class="text-gray-800 text-sm truncate leading-tight">
                    {{type?.name}} Analysis
                </div>
            </div>

            <div class="flex items-center gap-x-2">
                <span class="text-gray-500 text-sm" v-tooltip="'Recently used'" v-if="analysis.recent">
                    <ui-icon name="time"></ui-icon>
                </span>

                <span class="text-gray-500 text-sm" v-tooltip="'Pinned'" v-if="analysis.pinned">
                    <ui-icon name="pin-off"></ui-icon>
                </span>
            </div>

            <div class="flex items-center px-3">
                <slot name="actions">
                    <contextual-menu :analysis="analysis" plain></contextual-menu>
                </slot>
            </div>

            <div class="absolute inset-0 bg-gray-900 bg-opacity-50 rounded hidden group-hover:flex shadow-lg cursor-pointer items-center justify-center z-20" @click.prevent.stop="cardsStore.choose(analysis)" v-if="cardsStore.chooseCallback">
                <div class="rounded border border-white px-3 py-1 text-white font-medium">Choose</div>
            </div>
        </div>
    </component>
</template>

<script>
import ContextualMenu from '@/components/analysis/contextual-menus/analysis'

import useAnalysisTypesStore from '@/stores/analysis/analysis-types'

import { removeHTML } from '@/helpers/html'

import { mapState } from 'pinia'

export default {
    props: { analysis: {}, cardsStore: {}, mode: { default: 'card' } },

    components: { ContextualMenu },

    computed: {
        ...mapState(useAnalysisTypesStore, [ 'types' ]),

        type() {
            return this.types.find(type => this.analysis.type == type.id)
        },

        tooltip() {
            return { content: `<b>${this.analysis.name}</b><br>${removeHTML(this.analysis.description)}`, html: true }
        }
    }
}
</script>
