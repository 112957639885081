<template>
    <div :class="wrapperClass">
        <editor-content :class="inputClass" :editor="editor" @keyup.esc="blur"></editor-content>

        <Teleport to="#root">
            <div v-if="showCreateTopicDropdown" :style="createTopicDropdownStyle" v-click-away="createTopicClickAway" class="max-h-64 w-36 overflow-y-auto rounded py-0.5 drop-shadow-lg bg-white bg-opacity-95 backdrop-blur ring-1 ring-black ring-opacity-5 text-left">
                <a href="#" @click.prevent="createTopic" class="flex items-center my-0.5 mx-1 px-3 rounded leading-5 focus:outline-none transition duration-150 ease-in-out h-7 text-sm text-gray-900 hover:bg-blue-400 hover:text-white">
                    <ui-icon name="topic" class="mr-2"></ui-icon>
                    <span class="w-full">Save as a Topic</span>
                </a>
            </div>

            <div v-if="showSuggestedTopicsDropdown" :style="suggestedTopicsDropdownStyle" class="max-h-64 w-64 overflow-y-auto rounded py-0.5 drop-shadow-lg bg-white bg-opacity-95 backdrop-blur ring-1 ring-black ring-opacity-5 text-left">
                <a href="#" v-for="suggestedTopic, index in suggestedTopics" :key="suggestedTopic.id" @click.prevent="applySuggestedTopic(suggestedTopic)" class="flex items-center my-0.5 mx-1 px-3 rounded leading-5 focus:outline-none transition duration-150 ease-in-out h-7 text-sm text-gray-900 hover:bg-blue-400 hover:text-white">
                    <ui-avatar :item="suggestedTopic" class="w-4 h-4 mr-2 drop-shadow-sm shrink-0"></ui-avatar>
                    <span class="truncate w-full">{{ suggestedTopic.name }}</span>
                </a>
            </div>
        </Teleport>
    </div>
</template>

<script>

import InputTopic from './search-input-topic'

import useMyTopicsStore from '@/stores/me/topics'
import useSuggestionsStore from '@/stores/reusable/suggestions'
import useContentModalsTopicEditStore from '@/stores/content/modals/topic-edit'

import textSearch from '@/helpers/text-search'

import debounce from 'just-debounce-it'
import { mapActions, mapState } from 'pinia'
import { mergeAttributes, posToDOMRect } from '@tiptap/core'
import { Editor, EditorContent, Extension, VueNodeViewRenderer } from '@tiptap/vue-3'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import { Decoration, DecorationSet } from '@tiptap/pm/view'
import Paragraph from '@tiptap/extension-paragraph'
import Hardbreak from '@tiptap/extension-hard-break'
import Document from '@tiptap/extension-document'
import History from '@tiptap/extension-history'
import Mention from '@tiptap/extension-mention'
import Text from '@tiptap/extension-text'

export default {
    props: {
        'modelValue': { type: String, default: '' },
        'language': { type: String, default: '' },
        'allowMultiLine': { type: Boolean, default: false },
        'allowHighlighting': { type: Boolean, default: false },
        'allowSuggestingTopics': { type: Boolean, default: false },
        'allowCreatingTopics': { type: Boolean, default: false },
        'allowEditingTopics': { type: Boolean, default: false },
        'allowUnwrappingTopics': { type: Boolean, default: false },
        'wrapperClasses': { type: String, default: null },
        'focusedWrapperClasses': { type: String, default: null },
        'blurredWrapperClasses': { type: String, default: null },
        'classes': { type: String, default: null },
        'focusedClasses': { type: String, default: null },
        'blurredClasses': { type: String, default: null },
        'isFocused': { type: Boolean, default: false }
    },

    data: () => ({
        showSuggestedTopicsDropdown: false,
        suggestedTopicsDropdownXPosition: 0,
        suggestedTopicsDropdownYPosition: 0,
        suggestedTopics: [],
        suggestedTopicsCommand: null,

        showCreateTopicDropdown: false,
        createTopicDropdownXPosition: 0,
        createTopicDropdownYPosition: 0,

        editor: null,
        editorValue: null
    }),

    components: {
        EditorContent
    },

    mounted() {
        const allowMultiLine = this.allowMultiLine
        const allowHighlighting = this.allowHighlighting
        const allowEditingTopics = this.allowEditingTopics
        const allowUnwrappingTopics = this.allowUnwrappingTopics

        const highlight = Extension.create({
            name: 'highlight',

            addProseMirrorPlugins() {
                const highlight = (doc) => {
                    let results = []

                    doc.descendants((node, position) => {
                        if (! node.isText) { return }

                        const regex = /\b(AND|OR|NOT)\b(?=([^"]*"[^"]*")*[^"]*$)|\|(?!\w)|\+(?!\w)|([*~\-])/gm
                        let match = null

                        while ((match = regex.exec(node.text)) !== null) {
                            if (match.index === regex.lastIndex) { regex.lastIndex++ }

                            results.push({
                                type: ['~', '*'].includes(match[0]) ? 'operator' : 'keyword',
                                from: position + match.index,
                                to: position + match.index + match[0].length
                            })
                        }
                    })

                    const decorations = []

                    results.forEach(result => {
                        decorations.push(Decoration.inline(result.from, result.to, {
                            class: result.type === 'operator' ? 'text-rose-500 font-medium' : 'text-blue-600 font-medium'
                        }))
                    })

                    return DecorationSet.create(doc, decorations)
                }

                return [
                    new Plugin({
                        key: new PluginKey('highlight'),
                        state: {
                            init(_, { doc }) {
                                if (allowHighlighting) { highlight(doc) }
                            },

                            apply(transaction, oldState) {
                                return transaction.docChanged && allowHighlighting ? highlight(transaction.doc) : oldState
                            }
                        },
                        props: {
                            decorations(state) {
                                return this.getState(state)
                            },
                        }
                    })
                ]
            }
        })

        const shortcuts = Extension.create({
            name: 'shortcuts',

            addKeyboardShortcuts: () => ({
                'Enter': () => {
                    this.$emit('update:modelValue', this.editorValue = this.editor.getText('\n'))
                    this.$emit('submit')
                    return true
                },
                'Shift-Enter': () => {
                    if (allowMultiLine) {
                        this.editor.commands.setHardBreak()
                    }
                    return true
                }
            })
        })

        this.editor = new Editor({
            autocomplete: false,
            spellcheck: false,
            content: this.modelValue,
            extensions: [
                Hardbreak,
                Paragraph,
                Document,
                Text,
                History,
                Mention.extend({
                    addOptions() {
                        return {
                            ...this.parent?.(),
                            allowEditingTopics,
                            allowUnwrappingTopics
                        }
                    },
                    renderText({ node }) {
                        return ` /topic/${node.attrs.id} `
                    },
                    parseHTML() {
                        return [
                            {
                                tag: `input-topic[data-type="mention"]`
                            }
                        ]
                    },
                    renderHTML({ node, HTMLAttributes }) {
                        return [
                            'input-topic',
                            mergeAttributes({ 'data-type': this.name }, this.options.HTMLAttributes, HTMLAttributes),
                            this.options.renderLabel({
                                options: this.options,
                                node,
                            }),
                            ]
                    },
                    addNodeView() {
                        return VueNodeViewRenderer(InputTopic)
                    }
                }).configure({
                    suggestion: {
                        char: '/',
                        items: request => textSearch(request.query, this.topics, t => t.name),
                        command: ({ editor, range, props }) => {
                            const nodeAfter = editor.view.state.selection.$to.nodeAfter
                            const overrideSpace = nodeAfter?.text?.startsWith(' ')
                            if (overrideSpace) { range.to += 1 }

                            editor
                                .chain()
                                .focus()
                                .insertContentAt(range, [
                                    {
                                        type: 'mention',
                                        attrs: props,
                                    },
                                    {
                                        type: 'text',
                                        text: ' ',
                                    },
                                    ])
                                .run()

                            if (window.getSelection() !== null && window.getSelection()?.toString().length > 0) {
                                window.getSelection()?.collapseToEnd()
                            }
                        },
                        render: () => ({
                            onStart: props => {
                                if (! this.allowSuggestingTopics) { return }
                                this.showSuggestedTopicsDropdown = true

                                this.suggestedTopicsDropdownYPosition = props.clientRect().top
                                this.suggestedTopicsDropdownXPosition = props.clientRect().left
                                this.suggestedTopics = props.items
                                this.suggestedTopicsCommand = props.command
                            },
                            onUpdate: props => {
                                this.suggestedTopicsDropdownYPosition = props.clientRect().top
                                this.suggestedTopicsDropdownXPosition = props.clientRect().left
                                this.suggestedTopics = props.items
                                this.suggestedTopicsCommand = props.command
                            },
                            onExit: () => {
                                this.showSuggestedTopicsDropdown = false
                            }
                        })
                    }
                }),
                highlight,
                shortcuts
            ],
            onFocus: () => {
                this.focus()
            },
            onBlur: () => {
                this.blur()
            },
            onUpdate: () => {
                this.update()
            },
            onTransaction: ({ editor, transaction }) => {
                this.transaction({ editor, transaction })
            },
            onSelectionUpdate: ({ editor }) => {
                if (this.allowCreatingTopics && ! editor.state.selection.empty && this.isFocused) {
                    const clientRect = posToDOMRect(editor.view, editor.view.state.selection.from, editor.view.state.selection.to)
                    this.createTopicDropdownYPosition = clientRect.top
                    this.createTopicDropdownXPosition = clientRect.left

                    return this.showCreateTopicDropdown = true
                }

                return this.showCreateTopicDropdown = false
            }
        })
    },

    beforeUnmount() {
        this.editor.destroy()
    },

    methods: {
        ...mapActions(useSuggestionsStore, [ 'suggest' ]),

        focus() {
            this.editor.commands.focus()

            this.$emit('focus')
        },

        blur() {
            if (! this.editor.getText()) {
                this.editor.commands.clearContent()
            }

            this.editor.commands.blur()

            this.$emit('blur')
        },

        update() {
            this.editorValue = this.editor.getText('\n')

            this.model = this.editorValue
        },

        transaction({ editor, transaction }) {
            this.$emit('transaction', { editor, transaction })
        },

        processValue(value) {
            value = value.replaceAll(/\/topic\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/ig, (match, id) => {
                const topic = useMyTopicsStore().find(id)

                if (topic) {
                    return `<input-topic data-type="mention" data-id="${topic.id}" data-label="${topic.name}">/${topic.name}</input-topic>`
                }

                return match
            })

            value = value.replaceAll(/\n/g, '<br>')

            return value
        },

        createTopic() {
            this.showCreateTopicDropdown = false

            const query = this.editor.state.doc.textBetween(this.editor.state.selection.from, this.editor.state.selection.to, '', (node) => {
                if (node.type.name === 'mention') return `/topic/${node.attrs.id}`

                return ''
            }).trim()

            useContentModalsTopicEditStore().open({ query, language: this.language }).then(res => {
                if (res && res.topic) {
                    this.editor.commands.deleteSelection()
                    this.editor.commands.insertContentAt(this.editor.state.selection.from, `<input-topic data-type="mention" data-id="${res.topic.id}" data-label="${res.topic.name}">/${res.topic.name}</input-topic>`)
                } else {
                    this.focus()
                    this.showCreateTopicDropdown = true
                }
            })
        },

        applySuggestedTopic(suggestedTopic) {
            this.suggestedTopicsCommand({ id: suggestedTopic.id, label: suggestedTopic.name })
        },

        createTopicClickAway() {
            if (! this.isFocused) {
                this.showCreateTopicDropdown = false
            }
        }
    },

    computed: {
        ...mapState(useMyTopicsStore, [ 'topics' ]),

        model: {
            get() { return this.modelValue },
            set: debounce(function (val) { this.$emit('update:modelValue', val) }, 50)
        },

        wrapperClass() {
            const classes = this.wrapperClasses ?? 'bg-gray-100 flex-1 rounded'
            const focusedClasses = this.focusedWrapperClasses ?? 'h-auto'
            const blurredClasses = this.blurredWrapperClasses ?? 'h-10'

            return this.isFocused ? `${classes} ${focusedClasses}` : `${classes} ${blurredClasses}`
        },

        inputClass() {
            const classes = this.classes ?? 'h-full w-full overflow-y-hidden pl-10 pr-32 pt-2 pb-2 rounded-sm align-middle z-30 max-h-48 '
            const focusedClasses = this.focusedClasses ?? 'bg-white ring-2 ring-blue-500'
            const blurredClasses = this.blurredClasses ?? 'resize-none text-gray-900'

            return this.isFocused ? `${classes} ${focusedClasses}` : `${classes} ${blurredClasses}`
        },

        suggestedTopicsDropdownStyle() {
            return {
                position: 'absolute',
                zIndex: 9999,
                top: `${this.suggestedTopicsDropdownYPosition + 25}px`,
                left: `${this.suggestedTopicsDropdownXPosition}px`
            }
        },

        createTopicDropdownStyle() {
            return {
                position: 'absolute',
                zIndex: 9999,
                top: `${this.createTopicDropdownYPosition + 25}px`,
                left: `${this.createTopicDropdownXPosition}px`
            }
        }
    },

    watch: {
        modelValue(value) {
            if (value == this.editorValue) return

            this.editor.commands.setContent(this.processValue(value), false)
        }
    }
}
</script>

<style>
.ProseMirror {
    outline: none;
    overflow-y: hidden;
    min-height: 0;
}

.ProseMirror:focus {
    outline: none;
}

.ProseMirror p {
    @apply font-sans text-[14px] leading-[24px] pb-0;

    /*padding-bottom: 0;*/
    /*display: -webkit-box;*/
    /*-webkit-line-clamp: 1;*/
    /*-webkit-box-orient: vertical;*/
    /*overflow: hidden;*/
}

.ProseMirror-focused p {
    /*display: block;*/
    /*-webkit-line-clamp: none;*/
    /*-webkit-box-orient: vertical;*/
    /*overflow: initial;*/
}
</style>
