<template>
    <div class="fixed bg-white border border-gray:50 max-h-60 shadow-sm" :style="{ top: menuY + 'px', left: menuX + 'px' }" v-if="contextMenuShown" v-click-away="hideContextMenu">
        <ui-dropdown-link icon="check-square" @click="selectNode" keep-open>
            {{ store.selectedNodes.includes(selectedNode.node_id) ? 'Deselect node' : 'Select node ' }}
        </ui-dropdown-link>

        <ui-dropdown-link icon="info" @click="showDetails" keep-open>
            Show details
        </ui-dropdown-link>

        <ui-dropdown-link icon="share" @click="loadConnections" v-if="!store.inspectedNodeIds.includes(selectedNode.node_id) && store.inspectedNodeIds.length < 5 && store.nodes.length < 5000" keep-open>
            Load connections
        </ui-dropdown-link>

        <ui-dropdown-link icon="eye-off" @click="hideNode" keep-open>
            Hide node
        </ui-dropdown-link>

        <ui-dropdown-link icon="hash" @click="freezeNode" keep-open>
            {{ store.frozenNodes.includes(selectedNode.node_id) ? 'Unfreeze node' : 'Freeze node ' }}
        </ui-dropdown-link>

        <ui-dropdown-link icon="trash" @click="deleteNode" keep-open>
            Delete node
        </ui-dropdown-link>
    </div>
</template>


<script>

import { mapActions, mapState } from "pinia";
import useGraphEventsStore from "@/stores/connections/graph-events.js";

export default {
    props: {
        store: Object,
    },

    computed: {
        ...mapState(useGraphEventsStore, ['menuY', 'menuX', 'contextMenuShown', 'selectedNode']),
    },

    methods: {
        ...mapActions(useGraphEventsStore, [ 'hideContextMenu' ]),

        isActive(section) {
            return this.activeSection === section
        },

        showDetails() {
            if (this.selectedNode) {
                let name = ""
                const n = this.selectedNode

                switch (n.class) {
                    case "Channel":
                        name = 'channels.channel.information'
                        break

                    case "Feature":
                        name = 'features.details'
                        break

                    case "Content":
                        name = 'content.details'
                        break
                }

                window.open(this.$router.resolve({ name: name, params: { id: n.node_id } }).href, '_blank');
            }
        },

        loadConnections() {
            if (this.selectedNode) {
                this.store.inspectNode([ this.selectedNode.node_id ])
                this.hideContextMenu()
            }
        },

        hideNode() {
            if (this.selectedNode) {
                this.store.hideNode([ this.selectedNode.node_id ])
                this.hideContextMenu()
            }
        },

        deleteNode() {
            if (this.selectedNode) {
                this.store.deleteNode([ this.selectedNode.node_id ])
                this.hideContextMenu()
            }
        },

        selectNode() {
            if (this.selectedNode) {
                this.store.selectNodes([ this.selectedNode.node_id ])
                this.hideContextMenu()
            }
        },

        freezeNode() {
            if (this.store.selectedNode) {
                this.store.freezeNode([ this.selectedNode.node_id ])
                this.hideContextMenu()
            }
        }
    }
}
</script>
