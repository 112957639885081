import useContentModalsNotificationsManageStore from './notifications-manage'
import useMyStore from '@/stores/me/my'
import { defineForm } from '@/stores/reusable/form'

import api from '@/api'

export const useContentModalsNotificationsManageFormStore = defineForm({
    id: 'contentModalsNotificationsManageForm',

    inputs: () => ({
        perspective: {},
        subscription: {},

        enabled: true,
        name: null,

        configuration: {
            frequency: 'weekly',
            metric: 'interactions',
            retrospective: false,
            backoff: null,
            count: 10,

            email: {
                additionalRecipients: []
            },
            mattermost: {
                webhookUrl: null,
                username: null,
                channel: null
            }
        },

        channels: {
            inApp: false,
            email: false,
            mattermost: false
        },

        recipients: {
            inApp: { '*': false },
            email: { '*': false },
            mattermost: { '*': false }
        }
    }),

    submitRequest() {
        return api.route(this.inputs.subscription.id ? 'me perspectives subscriptions update' : 'me perspectives subscriptions store', { id: this.inputs.perspective.id, subscriptionId: this.inputs.subscription.id })
            .json({
                _method: this.inputs.subscription.id ? 'put' : 'post',

                enabled: this.inputs.enabled,
                name: this.inputs.name,
                type: this.inputs.type,

                configuration: this.inputs.configuration,

                channels: Object.entries(this.inputs.channels).reduce((channels, [ channel, enabled ]) => {
                    if (enabled) {
                        channels[channel] = Object.entries(this.inputs.recipients[channel])
                            .filter(([ channel, value ]) => value)
                            .map(([ channel, value ]) => channel)
                    }
                    return channels
                }, {})
            })
            .post()
    },

    async onResponse(res) {
        await useContentModalsNotificationsManageStore().reloadSubscriptions()
        useContentModalsNotificationsManageStore().editSubscription(
            this.inputs.perspective.subscriptions.find(s => s.id == res.data.id)
        )
    },

    actions: {
        edit(perspective, subscription) {
            this.reset()

            this.inputs.perspective = perspective || {}
            this.inputs.subscription = subscription || {}

            this.inputs.enabled = subscription?.enabled
            this.inputs.name = subscription?.name
            this.inputs.type = subscription?.type

            this.inputs.configuration.frequency = subscription?.configuration?.frequency
            this.inputs.configuration.metric = subscription?.configuration?.metric
            this.inputs.configuration.retrospective = subscription?.configuration?.retrospective
            this.inputs.configuration.backoff = subscription?.configuration?.backoff
            this.inputs.configuration.count = subscription?.configuration?.count

            this.inputs.configuration.email.additionalRecipients = subscription?.configuration?.email?.additionalRecipients || []

            this.inputs.configuration.mattermost.webhookUrl = subscription?.configuration?.mattermost?.webhookUrl
            this.inputs.configuration.mattermost.username = subscription?.configuration?.mattermost?.username
            this.inputs.configuration.mattermost.channel = subscription?.configuration?.mattermost?.channel

            this.inputs.channels.inApp = !! subscription?.channels?.inApp
            this.inputs.channels.email = !! subscription?.channels?.email
            this.inputs.channels.mattermost = !! subscription?.channels?.mattermost

            this.inputs.recipients.inApp = (subscription?.channels?.inApp || []).reduce((recipients, recipient) => ({ ...recipients, [recipient]: true }), {})
            this.inputs.recipients.email = (subscription?.channels?.email || []).reduce((recipients, recipient) => ({ ...recipients, [recipient]: true }), {})
            this.inputs.recipients.mattermost = (subscription?.channels?.mattermost || []).reduce((recipients, recipient) => ({ ...recipients, [recipient]: true }), {})
        },

        toggleRetrospective() {
            this.inputs.configuration.retrospective = ! this.inputs.configuration.retrospective
        },

        toggleChannel(channel) {
            if (this.inputs.channels[channel]) {
                this.inputs.channels[channel] = false
                this.inputs.recipients[channel]['*'] = false
            } else {
                this.inputs.channels[channel] = true
                this.inputs.recipients[channel]['*'] = true
            }
        },

        toggleRecipient(channel, recipient) {
            this.inputs.recipients[channel][recipient] = ! this.inputs.recipients[channel][recipient]

            if (recipient == '*') {
                useMyStore().currentWorkspace.users.forEach(user => this.inputs.recipients[channel][user.id] = true)
            }
        },

        delete() {
            return api.route('me perspectives subscriptions delete', { id: this.inputs.perspective.id, subscriptionId: this.inputs.subscription.id })
                .delete()
                .res(async res => {
                    await useContentModalsNotificationsManageStore().reloadSubscriptions()
                    useContentModalsNotificationsManageStore().refresh()
                })
        }
    }
})

export default useContentModalsNotificationsManageFormStore
