<template>
    <a href="#" @click.prevent="applySearch(streamStore, search)" class="block px-6 pb-1 pt-3 hover:bg-gray-50 hover:ring-1 ring-gray-200 ring-inset hover:bg-gray-50 hover:ring-1">
        <div class="flex flex-wrap items-center mt-2 gap-2 px-3">
            <div
                v-if="itemStreamStore.filters.values.text"
                @click.stop="applySearchQuery(streamStore, itemStreamStore)"
                v-tooltip="`Apply search query: ${itemStreamStore.filters.values.text.query}`"
                class="inline-flex items-center px-3 py-1.5 text-sm font-medium leading-none bg-gray-100 text-gray-900 rounded-sm max-w-full hover:bg-gray-300 leading-4"
            >
                <ui-icon name="search" class="text-xs mr-1 shrink-0"></ui-icon>
                <p class="line-clamp-3">{{ itemStreamStore.filters.values.text.query }}</p>
            </div>

            <template v-for="filter in visibleFilters" :key="filter">
                <filter-date v-if="filter === 'date'" :filters="itemStreamStore.filters"></filter-date>
                <filter-notification v-else-if="filter === 'notification'" :filters="itemStreamStore.filters" ephemeral></filter-notification>
                <filter-channels v-else-if="filter === 'sources' || filter === 'targets'" :filters="itemStreamStore.filters"></filter-channels>
                <filter-types v-else-if="filter === 'types'" :filters="itemStreamStore.filters"></filter-types>
                <filter-tags v-else-if="filter === 'tags'" :filters="itemStreamStore.filters"></filter-tags>
                <filter-performance v-else-if="filter === 'performance'" :filters="itemStreamStore.filters"></filter-performance>
                <filter-features v-else-if="filter === 'features'" :filters="itemStreamStore.filters" :stream-store="itemStreamStore"></filter-features>
                <filter-deleted v-else-if="filter === 'deleted'" :filters="itemStreamStore.filters"></filter-deleted>
                <filter-semantic v-else-if="filter === 'semantic'" :filters="itemStreamStore.filters" :stream-store="itemStreamStore"></filter-semantic>
                <filter-language v-else-if="filter === 'language'" :filters="itemStreamStore.filters"></filter-language>
            </template>
        </div>

        <p class="text-2xs text-gray-700 shrink-0 text-right">{{ $dateTime(search.createdAt) }}</p>
    </a>
</template>

<script>
import FilterChannels from '@/components/content/perspective/stream/search-filters/filter-channels'
import FilterDate from '@/components/content/perspective/stream/search-filters/filter-date'
import FilterDeleted from '@/components/content/perspective/stream/search-filters/filter-deleted'
import FilterFeatures from '@/components/content/perspective/stream/search-filters/filter-features'
import FilterLanguage from '@/components/content/perspective/stream/search-filters/filter-language'
import FilterNotification from '@/components/content/perspective/stream/search-filters/filter-notification'
import FilterPerformance from '@/components/content/perspective/stream/search-filters/filter-performance'
import FilterSemantic from '@/components/content/perspective/stream/search-filters/filter-semantic'
import FilterTags from '@/components/content/perspective/stream/search-filters/filter-tags'
import FilterTypes from '@/components/content/perspective/stream/search-filters/filter-types'

import defineStreamStore from '@/stores/reusable/stream'
import useModalsSearchHistoryStore from '@/stores/modals/search-history'

import searchFilters from '@/helpers/search-filters'

import { mapActions } from 'pinia'

export default {
    props: {
        search: {
            required: true
        },
        streamStore: {
            required: true
        },
        focused: {}
    },

    components: {
        FilterChannels, FilterDate, FilterDeleted, FilterFeatures, FilterLanguage, FilterNotification, FilterPerformance,
        FilterSemantic, FilterTags, FilterTypes
    },

    computed: {
        itemStreamStore() {
            let store = defineStreamStore({
                id: 'stream-store-' + this.search.id,
                filters: searchFilters(),
                sortingOptions: [ ...this.streamStore.sortingOptions ]
            })()

            store.appliedFilters = Object.entries(this.search.filters)
                .map(([filter, value]) => store.filters.set(filter, value, true))

            return store
        },

        visibleFilters() {
            return Object.keys(this.itemStreamStore.filters.values)
        }
    },

    methods: {
        ...mapActions(useModalsSearchHistoryStore, {
            applySearch: 'apply',
            applySearchQuery: 'applyQuery'
        })
    }
}
</script>
