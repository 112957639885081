import { useRouter } from '@/helpers'

import { defineStore } from 'pinia'

export const defineMultiCards = settings => {
    return defineStore({
        id: settings.id,

        state: () => ({
            contexts: settings.contexts,
            
            isShowing: Object.keys(settings.contexts)[0],

            params: {}
        }),

        getters: {
            currentContext(store) {
                return store.contexts[store.isShowing]()
            },
            
            ...[
                'availableLayoutOptions', 'availableSortingOptions', 'items', 'sorting', 'source', 'layout',
                'layoutSettings', 'isInitialized', 'isLoading', 'isEmpty', 'searchable', 'paginated', 'searchQuery',
                ...(settings.mapGetters || [])
            ].reduce((getters, getter) => {
                return { ...getters, [getter]: store => store.currentContext[getter] }
            }, {})
        },

        actions: {
            initialize(query) {
                query = query || ''

                let context = query.split('|')[0] || this.isShowing
                let params = query.split('|')[1] || ''

                params = params.split(',').reduce((params, param) => ({
                    ...params, [param.split(':')[0]] : param.split(':')[1]
                }), {})

                this.isShowing = context
                this.currentContext.initialize(this.params = params)
            },

            show(context, params = {}) {
                params = Object.entries(params).map(([ key, value ]) => `${key}:${value}`).join(',')

                useRouter().replace({ query: { show: context + (params ? `|${params}` : '') } })
            },
            
            back() {
                settings.back?.()
            },

            setSorting(option) {
                this.currentContext.setSorting(option)
            },

            setLayout(layout) {
                this.currentContext.setLayout(layout)
            },

            refreshLayouts(width) {
                this.currentContext.refreshLayouts(width)
            },

            search(query) {
                this.currentContext.search(query)
            },

            choose(item) {
                this.currentContext.choose(item)
            }
        }
    })
}

export default defineMultiCards
