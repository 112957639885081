import { default as useRouter } from '@/router'

import useMyStore from '@/stores/me/my'
import useSupportDebugStore from '@/stores/support/debug'

import { date, dateTime } from '@/helpers/datetime'
import number from '@/helpers/number'
import { nodeType } from '@/helpers/types'

import { mapActions } from 'pinia'

export default function registerHelpers(app) {
    app.mixin({
        computed: {
            $my() { return useMyStore() },
            $page() { return useRouter().currentPage },

            isMac() { return navigator.platform.indexOf('Mac') > -1 }
        },
        methods: {
            $date: date,
            $dateTime: dateTime,
            $nodeType: nodeType,
            $number: number,

            ...mapActions(useMyStore, { $can: 'can', $canAny: 'canAny', $hasFeatureTo: 'hasFeatureTo' }),
            ...mapActions(useSupportDebugStore, { $inspect: 'show' })
        }
    })
}

import { $vfm } from 'vue-final-modal'

export function useModal() {
    return $vfm
}

export { useRoute as useRoute } from '@/router'
export { default as useRouter } from '@/router'
